/**
 * Record of all app routes alongside authorization info. Use [] to denote
 * variable for next router compatibility
 */
export const Route = {
  login: {
    path: '/auth/login',
  },
  loginAcv: {
    path: '/auth/loginacv',
  },
  overview: {
    path: '/',
  },
  leadList: {
    path: '/leads',
  },
  leadDetail: {
    path: '/leads/[id]',
  },
  leadAdd: {
    path: '/leads/add',
  },
  appointmentList: {
    path: '/appointments',
  },
  appointmentDetail: {
    path: '/appointments/[id]',
  },
  appraisalList: {
    path: '/appraisals',
  },
  appraisalDetail: {
    path: '/appraisals/[id]',
  },
  appraisalDetailOffer: {
    path: '/appraisals/[id]/offer',
  },
  offerDetail: {
    path: '/offer/[uuid]',
  },
  damageReportPdfDetail: {
    path: '/damage/[inspection_id]',
  },
  disclosureAdd: {
    path: '/disclosures/add',
  },
  disclosurePhotoUpload: {
    path: '/disclosures/photo-upload',
  },
  disclosureThankyou: {
    path: '/disclosures/thank-you',
  },
  notificationListPage: {
    path: '/notifications',
  },
  vehicleThankyou: {
    path: '/vehicle/[vehicle_uuid]/thank-you',
  },
};

export const LoginRoutes = [Route.login.path, Route.loginAcv.path];

export const PublicRoutes = [
  Route.disclosureAdd.path,
  Route.disclosurePhotoUpload.path,
  Route.disclosureThankyou.path,
  Route.offerDetail.path,
  Route.vehicleThankyou.path,
];

// Routes that can be accessed without auth, automatically has no navbar
export const RoutesWithoutAuth = [...LoginRoutes, ...PublicRoutes];

// Default route to redirect to for auth
export const LoginRedirectRoute = Route.loginAcv.path;

// Display the sidebar on these routes
export const RoutesWithSideBar = [
  Route.appointmentList.path,
  Route.appraisalList.path,
  Route.leadList.path,
];

// Hide navigation bar on these routes
export const RoutesWithoutNavBar = [
  ...RoutesWithoutAuth,
  Route.offerDetail.path,
];

const URLRetainKeys = ['gtm_debug'];

/** Process url with search param key filtering and other features */
export const processUrl = ({ url }) => {
  const currentUrl = new URL(window.location);

  for (const [key, value] of currentUrl.searchParams.entries()) {
    if (!URLRetainKeys.includes(key)) {
      currentUrl.searchParams.delete(key, value);
    }
  }

  const newUrl = url ? [url, currentUrl.searchParams.toString()].join('?') : '';

  return {
    newUrl,
    searchParams: currentUrl.searchParams,
    url,
  };
};

/** Match page url path to a navigation route (e.g. /page/1 vs /page/[id]) */
export const matchPathToRoute = (path, route, matchRegexStr = '\\d+') => {
  const regex = new RegExp(`^${route.replace(/\[.*?\]/g, matchRegexStr)}$`);
  return regex.test(path);
};
