import { styled } from './lib';

const A = ({
  isPlaceholder = false,
  isUnderlined = false,
  onClick,
  shouldOpenNewTab = false,
  ...props
}) => {
  const handleClick = (e) => {
    const isCtrlKeyPressed = e.metaKey || e.ctrlKey;

    // Do not open link if used as placeholder for native routers
    if (isPlaceholder && !isCtrlKeyPressed) {
      e.preventDefault();
    }

    // Enable natural behavior if ctrl key is pressed. Otherwise, toggle onClick handler.
    if (isCtrlKeyPressed) {
      e.stopPropagation();
    } else {
      onClick?.(e);
    }
  };
  return (
    <AStyled
      {...props}
      {...(isPlaceholder && { 'data-placeholder': true })}
      {...(shouldOpenNewTab && { target: '_blank', rel: 'noreferrer' })}
      underlined={isUnderlined ? 1 : 0}
      onClick={handleClick}
    />
  );
};

export default A;

const AStyled = styled('a')`
  text-transform: ${({ textTransform = 'normal' }) => textTransform};
  justify-content: ${({ justifyContent = 'start' }) => justifyContent};
  text-decoration: none;

  ${({ underlined }) => underlined && `text-decoration: underline;`}
`;
