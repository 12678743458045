import { useRouter } from 'next/router';
import qs from 'qs';
import { useEffect, useState } from 'react';

import { useDebounce } from './useDebounce';
import { useDebounceCallback } from './useDebounceCallback';
import { useQuery } from './useQuery';

const UrlParamKey = {
  Filter: 'f',
  Limit: 'l',
  Page: 'p',
  SearchTerm: 's',
  typeOfSearch: 't',
  filterColumn: 'fc',
};

const InitialState = {
  filter: {},
  limit: 10,
  page: 1,
  searchTerm: '',
  typeOfSearch: 'desc_nulls_last',
  filterColumn: 'created_at',
};

const StateToKeyMap = {
  filter: UrlParamKey.Filter,
  limit: UrlParamKey.Limit,
  page: UrlParamKey.Page,
  searchTerm: UrlParamKey.SearchTerm,
  typeOfSearch: UrlParamKey.typeOfSearch,
  filterColumn: UrlParamKey.filterColumn,
};

const unsortedColumns = ['mi', 'offer', 'appraisal', 'appraiser'];

/**
 * Handle search with query options, debounce and routing Takes two GQL queries:
 * first for when there's no search term second one for when there is This is
 * separated for performance reasons
 */
export const useSearchQuery = (gqlNoTerm, gql, { errorText, getVariables }) => {
  const router = useRouter();

  const initializeOptions = () => {
    const query = qs.parse(window.location.search.slice(1));

    const filter = query[UrlParamKey.Filter] || InitialState.filter;
    const limit = query[UrlParamKey.Limit] || InitialState.limit;
    const page = query[UrlParamKey.Page] || InitialState.page;
    const searchTerm = query[UrlParamKey.SearchTerm] || InitialState.searchTerm;

    const typeOfSearch =
      query[UrlParamKey.typeOfSearch] || InitialState.typeOfSearch;

    const filterColumn =
      query[UrlParamKey.filterColumn] || InitialState.filterColumn;

    return {
      ...InitialState,
      filter,
      hasSearchChanged: false,
      limit: Number(limit),
      page: Number(page),
      searchTerm,
      typeOfSearch,
      filterColumn,
    };
  };

  const [options, setOptions] = useState(initializeOptions);

  const setUrlParam = useDebounceCallback((opts) => {
    const query = qs.parse(window.location.search.slice(1));

    const searchParam = Object.entries(opts).reduce((query, [key, value]) => {
      const urlKey = StateToKeyMap[key];
      if (!urlKey) {
        return query;
      }

      const isFalsyObject =
        typeof value === 'object' &&
        !Object.values(value).some((v) => {
          return Array.isArray(v) ? Boolean(v.length) : Boolean(v);
        });

      // If value is "falsy", remove from url. Otherwise, add to url.
      if (!value || InitialState[key] === value || isFalsyObject) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [urlKey]: _, ...cleanedQuery } = query;
        return cleanedQuery;
      } else {
        return { ...query, [urlKey]: value };
      }
    }, query);

    router.push(
      {
        pathname: router.pathname,
        search: qs.stringify(searchParam),
      },
      undefined,
      {
        shallow: true,
      }
    );
  });

  useEffect(() => setUrlParam(options), [options]);

  const searchTermDebounced = useDebounce(options.searchTerm);

  const variables = getVariables?.({
    ...options,
    searchTerm: searchTermDebounced,
  });

  // Use search_vehicles query only if a search term is present for performance reasons
  let [data, isLoading, networkStatus] = [null, false, 0];

  const isEmptySearch = variables.search === '%%';
  if (isEmptySearch) {
    ({ data, isLoading, networkStatus } = useQuery(gqlNoTerm, {
      errorText,
      fetchPolicy: 'cache-and-network',
      variables,
      notifyOnNetworkStatusChange: true,
    }));
  } else {
    ({ data, isLoading, networkStatus } = useQuery(gql, {
      errorText,
      fetchPolicy: 'cache-and-network',
      variables,
      notifyOnNetworkStatusChange: true,
    }));
  }

  const setOptionsByKey = (key, value) => {
    const newQuery = {
      ...options,
      hasSearchChanged: key === 'searchTerm',
      [key]: value,
      // Reset page when changing search, filter and limit
      ...(key !== 'page' && { page: InitialState.page }),
    };

    setOptions(newQuery);
  };

  const onSortChanged = (data) => {
    let changed = false;
    data.columnApi.columnModel.gridColumns.forEach((e) => {
      const { colId: columName, sort: typeSearch } = e;
      if (columName && typeSearch) {
        if (unsortedColumns.includes(columName)) return;
        setOptions((prevOptions) => ({
          ...prevOptions,
          filterColumn: columName,
          typeOfSearch: `${typeSearch}_nulls_last`,
        }));
        changed = true;
      }
    });
    if (!changed) {
      setOptions((prevOptions) => ({
        ...prevOptions,
        filterColumn: 'created_at',
        typeOfSearch: 'desc_nulls_last',
      }));
    }
  };
  const onSearchChange = (e) => setOptionsByKey('searchTerm', e.target.value);
  const onLimitChange = (e) => setOptionsByKey('limit', Number(e.target.value));

  const onNextPageClick = () => setOptionsByKey('page', options.page + 1);
  const onPrevPageClick = () => {
    setOptionsByKey('page', Math.max(options.page - 1, 1));
  };

  const onFilterChange = (filter) => setOptions({ ...options, filter });
  const onFilterChangeByKey = ({ key, value }) => {
    setOptionsByKey('filter', { ...options.filter, [key]: value });
  };

  const onTabChange = () => {
    setOptions(InitialState);
  };

  return {
    ...options,
    data,
    isLoading,
    networkStatus,
    onFilterChange,
    onFilterChangeByKey,
    onLimitChange,
    onNextPageClick,
    onPrevPageClick,
    onSearchChange,
    onSortChanged,
    onTabChange,
    variables,
  };
};
