import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import SelectMui from '@mui/material/Select';

import { styled } from './lib';
import { setPopoverBoxStyle } from './Popover';

const Select = ({
  helpText = '',
  isCompact = false,
  label = '',
  labelId = '',
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY,
  minWidth,
  options = [],
  shouldDisplayEmpty = true,
  width,
  ...props
}) => {
  const renderedOptions = options.map(({ text, value }) => (
    <MenuItem key={value} value={value}>
      {text}
    </MenuItem>
  ));

  return (
    <FormControlStyled
      sx={{
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        marginX,
        marginY,
        minWidth,
        width,
      }}
    >
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <SelectMuiStyled
        {...props}
        displayEmpty={shouldDisplayEmpty}
        isCompact={isCompact}
        label={label}
        labelId={labelId}
      >
        {renderedOptions}
      </SelectMuiStyled>
      {helpText && <FormHelperText>helpText</FormHelperText>}
    </FormControlStyled>
  );
};

export default Select;

const SelectMuiStyled = styled(
  ({ className, ...props }) => (
    <SelectMui
      {...props}
      classes={{ select: className }}
      MenuProps={{
        classes: { root: className },
      }}
    />
  ),
  {
    shouldForwardProp: (name) => !['isCompact'].includes(name),
  }
)`
  &.MuiSelect-select {
    ${({ isCompact }) => isCompact && `padding-top: 6px; padding-bottom: 6px;`}
  }

  .MuiPaper-root {
    ${setPopoverBoxStyle}
  }
`;

const FormControlStyled = styled(FormControl)`
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid
      ${({ theme }) => theme.color.backgrounds.borders}!important;
  }
`;
