import { ThemeProvider as ThemeProviderMui } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { theme } from './theme';

const ThemeProvider = ({ children }) => {
  return (
    <ThemeProviderMui theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProviderMui>
  );
};

export default ThemeProvider;
