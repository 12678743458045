import Chip from '@mui/material/Chip';

import { styled } from './lib';
import Icon from './Icon';

const StatusType = {
  Error: 'error',
  Info: 'info',
  Success: 'success',
  Warning: 'warning',
};

const Status = ({ icon = null, label = '', ...props }) => (
  <ChipStyled
    {...props}
    icon={icon && <Icon icon={icon} size="sm" marginRight="4px" />}
    label={label}
  />
);

export default Status;

const ChipStyled = styled(Chip)`
  padding: 4px 6px;
  border-radius: 3px;
  height: auto;

  .Chip-label {
    padding: 0;
    text-transform: capitalize;
    ${({ theme }) => theme.typography.GlobalText.statusBadgeBase}
  }

  ${({ type = StatusType.Info, theme }) => {
    const map = {
      [StatusType.Info]: `
        background: ${theme.color.ACVInformationalShades.uiInformationalColorXLight};

        .Chip-label {
          color: ${theme.color.ACVInformationalShades.uiInformationalColorBase};
        }
      `,
      [StatusType.Warning]: `
        background: ${theme.color.ACVPrimaryShadesLm.primaryColorXLight};

        .Chip-label {
          color: ${theme.color.ACVPrimaryShadesLm.primaryColorBase};
        }
      `,
      [StatusType.Error]: `
        background: ${theme.color.ACVErrorAlertShades.uiErrorColorXLight};

        .Chip-label {
          color: ${theme.color.ACVErrorAlertShades.uiErrorColorBase};
        }
      `,
      [StatusType.Success]: `
        background: ${theme.color.ACVSuccessShadesLm.uiSuccessColorXLight};

        .Chip-label {
          color: ${theme.color.ACVSuccessShadesLm.uiSuccessColorBase};
        }
      `,
    };

    return map[type];
  }}
`;
