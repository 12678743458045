import { useReducer, useMemo } from 'react';

import { bindActions } from '@portal/lib';
import { TableContext, TableActionsContext } from './hooks';
import { tableReducer, tableActions } from './ducks';

const TableProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tableReducer, {});

  const tableActionsWithDispatch = useMemo(
    () => bindActions(tableActions, dispatch),
    [dispatch]
  );

  return (
    <TableActionsContext.Provider value={tableActionsWithDispatch}>
      <TableContext.Provider value={state}>{children}</TableContext.Provider>
    </TableActionsContext.Provider>
  );
};

export default TableProvider;
