import { useState } from 'react';

const DefaultStepIndex = 0;
const DefaultMetadata = {};

/**
 * Manage behavior of several steps. Steps can be used alongside modals,
 * accordions and etc. Shared state between steps is represented by metadata.
 *
 * @param {object} props - Hooks configuration.
 * @param {{ id: string; content: Function }[]} props.stepsList - List of steps
 *   to render.
 */
export const useSteps = ({ stepList, handleClose }) => {
  const [activeStepIndex, setActiveStepIndex] = useState(DefaultStepIndex);
  const [metadata, setMetadata] = useState(DefaultMetadata);

  // Navigate to next step
  const next = () => {
    if (activeStepIndex <= stepList.length - 2) {
      setActiveStepIndex(activeStepIndex + 1);
    }
  };

  // Navigate to previous step
  const back = () => {
    if (activeStepIndex >= 1) {
      setActiveStepIndex(activeStepIndex - 1);
    }
  };

  // Navigate to a specific step using step ID
  const skip = (stepId) => {
    const targetIndex = stepList.findIndex(({ id }) => id === stepId);
    if (targetIndex >= 0) {
      setActiveStepIndex(targetIndex);
    }
  };

  // Navigate to start step and clear metadata
  const reset = () => {
    setActiveStepIndex(DefaultStepIndex);
    setMetadata(DefaultMetadata);
  };

  const currentStep = stepList[activeStepIndex] ?? {};
  const { content } = currentStep;

  // Handle step metadata changes using step ID as key
  const setStepMetadata = (stepId, data) => {
    if (!stepId) {
      return console.error('Unable to set step metadata. Step ID is required.');
    }

    setMetadata((state) => ({
      ...state,
      [stepId]: {
        ...state[stepId],
        ...data,
      },
    }));
  };

  // Handle metadata changes only to currently active step
  const setCurrentStepMetadata = (data) =>
    setStepMetadata(currentStep.id, data);

  const navigate = {
    back,
    next,
    reset,
    skip,
  };

  const props = {
    handleClose,
    metadata,
    navigate,
    setCurrentStepMetadata,
    setStepMetadata,
  };

  const renderedContent = content?.(props);

  return {
    ...props,
    renderedContent,
  };
};
