import { useQuery as useQueryAC } from '@apollo/client';

import { useAlert } from './useAlert';

/**
 * Integrate error handling and alert notification on apollo client useQuery
 * hook
 */
export const useQuery = (
  query,
  {
    errorText,
    onError,
    onCompleted,
    shouldSkip = false,
    pollInterval = 0,
    ...props
  } = {}
) => {
  const { setErrorAlert } = useAlert();

  const handleError = (error) => {
    setErrorAlert(errorText);
    onError?.(error);
  };

  const {
    data = {},
    loading: isLoading,
    ...result
  } = useQueryAC(query, {
    ...props,
    pollInterval,
    skip: shouldSkip,
    onError: handleError,
    onCompleted: (data) => onCompleted?.(data),
  });

  return {
    data,
    isLoading,
    ...result,
  };
};
