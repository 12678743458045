import { Box, Button } from '@drivably-apps/component-lib';
import { Tooltip } from '@portal/ui';

/** ModalAction renders a set of action buttons. */
export const ModalAction = ({
  backId,
  backText,
  isDisabled,
  isLoading,
  nextId,
  nextText,
  onBackClick,
  onNextClick,
  isNextButtonRounded,
  nextButtonTooltipText,
}) => (
  <Box justify="center" flexGap="md">
    {backText && (
      <Button id={backId} onClick={onBackClick} variant="outlined">
        {backText}
      </Button>
    )}
    {nextText && (
      <Tooltip
        title={nextButtonTooltipText}
        isActive={isDisabled}
        placement="top"
      >
        <Button
          color={isDisabled ? 'secondary' : 'primary'}
          id={nextId}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={onNextClick}
          isRounded={isNextButtonRounded}
        >
          {nextText}
        </Button>
      </Tooltip>
    )}
  </Box>
);
