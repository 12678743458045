import { Box, Text } from '@drivably-apps/component-lib';
import { styled } from './lib';
import Flex from './Flex';

export const BackgroundType = {
  Primary: 'primary',
  Warning: 'warning',
  None: 'none',
};

const PriceTableCard = ({ titleList = [], children, headerColor }) => {
  return (
    <TableCardContainer>
      <CardHeader headerColor={headerColor}>
        {titleList.map((item, index) => {
          return (
            item.showPrice && (
              <Box paddingVertical="sm" className="headerItem" key={index}>
                <Text color="light">{item.title}</Text>
              </Box>
            )
          );
        })}
      </CardHeader>

      {/* Content */}
      <CardContent direction="column">{children}</CardContent>
    </TableCardContainer>
  );
};

export default PriceTableCard;

const TableCardContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.color.raw.white};
  min-height: 144px;
  min-width: 235px;
  height: fit-content;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  flex-direction: column;
`;

const CardHeader = styled(Flex, {
  shouldForwardProp: (name) => !['headerColor'].includes(name),
})`
  background-color: ${({ theme, headerColor = BackgroundType.None }) => {
    switch (headerColor) {
      case BackgroundType.Primary:
        return 'var(--drvtrd-clr-primary-m)';
      case BackgroundType.Warning:
        return theme.color.ACVPrimaryShadesLm.primaryColorBase;
      default:
        return 'inherit';
    }
  }};
  max-width: 100%;
  justify-content: space-around;
  border-radius: 2px 2px 0px 0px;
  & .headerItem {
    .header-text {
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
`;

const CardContent = styled(Flex)`
  max-width: 100%;
  & .content-text {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin: 0px 0px 15px 0px;
  }
`;
