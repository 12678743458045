import BoxMui from '@mui/material/Box';

import { styled } from './lib';

const Box = ({ isOverflowAuto = false, ...props }) => (
  <BoxStyled {...props} isOverflowAuto={isOverflowAuto} />
);

export default Box;

const BoxStyled = styled(BoxMui, {
  shouldForwardProp: (name) => !['isOverflowAuto'].includes(name),
})`
  ${({ isOverflowAuto }) => isOverflowAuto && { overflow: 'auto' }}
`;
