import { useEffect } from 'react';
import { useReactiveVar, makeVar } from '@apollo/client';

import { useLocale } from './useLocale';

const alertVar = makeVar({});

const AlertDuration = 5000;

/** Set global alerts generally for async operations */
export const useAlert = () => {
  const { locale } = useLocale();
  const alert = useReactiveVar(alertVar);

  const setAlert = (type, defaultMessage = '') => {
    return (message = defaultMessage) => alertVar({ type, message });
  };

  const setSuccessAlert = setAlert('success', locale.appSuccess);
  const setErrorAlert = setAlert('error', locale.appError);
  const setWarningAlert = setAlert('warning');

  const clearAlert = () => alertVar({ type: alert.type, message: '' });

  useEffect(() => {
    if (alert.message) {
      const id = setTimeout(clearAlert, AlertDuration);
      return () => clearTimeout(id);
    }
  }, [alert.message]);

  return {
    alert,
    clearAlert,
    setErrorAlert,
    setSuccessAlert,
    setWarningAlert,
  };
};
