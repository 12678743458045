import { useRef, useLayoutEffect } from 'react';
import cls from 'clsx';
import { Modal as ModalBase, Box } from '@drivably-apps/component-lib';

import { useSteps, StepContextProvider, useDeviceViewport } from '@portal/ui';

/** ModalSteps manages and renders an overlay with series of steps. */
export const ModalSteps = ({
  isHeightFixed = false,
  isOpen,
  onClose,
  onReset,
  stepList,
}) => {
  // Track reset
  const resetRef = useRef(false);
  const { isMobile } = useDeviceViewport();

  const {
    handleClose,
    metadata,
    navigate,
    renderedContent,
    setCurrentStepMetadata,
    setStepMetadata,
  } = useSteps({
    handleClose: onClose,
    stepList,
  });

  useLayoutEffect(() => {
    // Reset step if modal has closed and reset is intended
    if (!isOpen && resetRef.current) {
      navigate.reset();
      onReset?.();

      resetRef.current = false;
    }
  }, [isOpen]);

  // Handle closing of modal but also reset the steps
  const handleCloseAndReset = () => {
    resetRef.current = true;
    onClose?.();
  };

  // Features accessible through useStepContext
  const stepContextValue = {
    handleClose,
    handleCloseAndReset,
    metadata,
    navigate,
    setCurrentStepMetadata,
    setStepMetadata,
  };

  const classNames = cls('drvtrd-modal-steps', {
    'drvtrd-modal-steps--mobile': isMobile,
    'drvtrd-modal-steps--fixed': isHeightFixed,
  });

  return (
    <StepContextProvider value={stepContextValue}>
      <ModalBase isOpen={isOpen}>
        <Box className={classNames}>{renderedContent}</Box>
      </ModalBase>
    </StepContextProvider>
  );
};
