import { split, ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';

import { HasuraUrl, HasuraWebsocketUrl } from './config';

export const createClient = (token) => {
  const authHeader = token ? { Authorization: `Bearer ${token}` } : {};

  const authLink = setContext((_, { headers }) => ({
    headers: { ...headers, ...authHeader },
  }));

  const httpLink = new HttpLink({ uri: HasuraUrl });

  const wsLink = new WebSocketLink({
    uri: HasuraWebsocketUrl,
    options: {
      reconnect: true,
      connectionParams: {
        headers: authHeader,
      },
    },
  });

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink
  );

  const cache = new InMemoryCache();

  const client = new ApolloClient({
    cache,
    link: authLink.concat(link),
  });

  return client;
};
