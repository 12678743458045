import Skeleton from '@mui/material/Skeleton';

import { styled } from './lib';

const Loader = ({ type, ...props }) => (
  <SkeletonStyled
    {...props}
    variant={type}
    sx={{ backgroundColor: (theme) => theme.color.ACVBgLm.background1Color }}
  />
);

export default Loader;

const SkeletonStyled = styled(Skeleton)`
  transform-origin: 0 0;
  transform: unset;
`;
