import Text from './Text';

import { styled } from './lib';

const TablePlaceholder = ({ placeholderText, otherText }) => (
  <TablePlaceholderStyled className="ag-overlay-loading-center">
    <PlaceHolderTextOne>{placeholderText}</PlaceHolderTextOne>
    <PlaceHolderTextTwo marginTop={1}>{otherText}</PlaceHolderTextTwo>
  </TablePlaceholderStyled>
);

export default TablePlaceholder;

const TablePlaceholderStyled = styled('div')`
  && {
    border: 0;
    background: none;
    box-shadow: none;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }
`;

const PlaceHolderTextOne = styled(Text)`
  font-size: 24px;
  font-weight: 500;
  color: #212121;
`;
const PlaceHolderTextTwo = styled(Text)`
  font-weight: 400;
  color: #212121;
`;
