import useMediaQuery from '@mui/material/useMediaQuery';

export { useMediaQuery };

const ViewportWidthMobile = 480;

/**
 * Detect if user is running the site on mobile or desktop. Width by device is
 * sourced from https://viewportsizer.com/devices.
 */
export const useDeviceViewport = () => {
  const isMobile = useMediaQuery(`(max-width:${ViewportWidthMobile}px)`);

  return {
    isDesktop: !isMobile,
    isMobile,
  };
};
