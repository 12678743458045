import Stack from '@mui/material/Stack';

import { styled } from './lib';

const Flex = ({
  isCentered = false,
  isOverflowAuto = false,
  direction = 'row',
  ...props
}) => (
  <StackStyled
    {...props}
    direction={direction}
    isOverflowAuto={isOverflowAuto}
    {...(isCentered && {
      alignItems: 'center',
      justifyContent: 'center',
    })}
  />
);

export default Flex;

const StackStyled = styled(Stack, {
  shouldForwardProp: (name) => !['isOverflowAuto'].includes(name),
})`
  ${({ isOverflowAuto }) => isOverflowAuto && { overflow: 'auto' }}
`;
