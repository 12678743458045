import { useState } from 'react';

import { UsedCarClubUrl, logger } from '@portal/lib';
import { restApi } from '@portal/api';
import { useFetch } from './useFetch';
import { useVehicleTaxonomy } from './useVehicleTaxonomy';

export const useFetchVehiclePhoto = () => {
  const [photoSrc, setPhotoSrc] = useState('');
  const [taxonomySquishVin, setTaxonomySquishVin] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch UVC from as requirement for photos
  const [fetchUvc, fetchUvcRes] = useFetch({ baseURL: UsedCarClubUrl });

  // Fetch vehicle taxonomy for squish vin
  const {
    fetchVehicleTaxonomy,
    isLoading: isVehicleTaxonomyLoading,
    error: vehicleTaxonomyError,
  } = useVehicleTaxonomy();

  // Fetch photo using UVC
  const getVehiclePhoto = async ({
    bb_uvc,
    make,
    model,
    style,
    trim,
    vin,
    year,
  }) => {
    setIsLoading(true);

    try {
      let blackBookSquishVin = '';

      if (!vin) {
        // Call taxonomy first for the squishVin given the year
        const taxonomyResponse = await fetchVehicleTaxonomy(year);

        const acvSquishVin =
          taxonomyResponse.data?.[make]?.[model]?.[trim]?.[style] ?? '';

        setTaxonomySquishVin(acvSquishVin);

        // Truncate last character for compatibility with blackbook photos API
        blackBookSquishVin = acvSquishVin.slice(0, -1);
      }

      let photosUvc = bb_uvc;
      if (!photosUvc) {
        const vinData = vin || blackBookSquishVin;

        // Fetch uvc
        const uvcRes = await fetchUvc({
          url: `/UsedVehicle/VIN/${vinData}`,
          params: {
            country: 'U',
            drilldeep: 'true',
            mileage: '0',
          },
        });

        photosUvc = uvcRes.used_vehicles?.used_vehicle_list?.[0].uvc;
        if (!photosUvc) {
          logger.error('Unable to retrieve uvc.');
          return;
        }
      }

      // Fetch photo
      const photoResponse = await restApi.get(
        `${UsedCarClubUrl}/Photos/${photosUvc}?size=large`
      );

      const hasError = photoResponse?.data.error_count;
      const errorMessage = photoResponse?.data.message_list[0]?.description;
      const photo = photoResponse?.data.photo.file_contents
        ? `data:image/png;base64, ${photoResponse?.data.photo.file_contents}`
        : '';

      setPhotoSrc(photo);
      setError(hasError ? errorMessage : null);
    } catch (error) {
      setPhotoSrc(null);
      setError(error);
    }

    setIsLoading(false);
  };

  const isLoadingVehiclePhoto =
    isLoading || fetchUvcRes.isLoading || isVehicleTaxonomyLoading;

  return {
    getVehiclePhoto,
    isLoadingVehiclePhoto,
    taxonomySquishVin,
    vehiclePhotoError: error || fetchUvcRes.error || vehicleTaxonomyError,
    vehiclePhotoSrc: photoSrc,
  };
};
