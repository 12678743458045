import { useFetch } from '@portal/hooks';
import { ServiceUrl } from '@portal/lib';

export const useFetchVehiclePrice = ({
  errorText,
  isErrorAlertMuted,
  onError,
  onSuccess,
} = {}) => {
  const [fetch, fetchRes] = useFetch({
    errorText,
    isErrorAlertMuted,
    onError,
    onSuccess,
  });

  const fetchVehiclePrice = ({ answers, zipCode, partnerId }) => {
    const config = {
      method: 'post',
      headers: { 'Content-Type': 'text/plain' },
      url: `${ServiceUrl}/VehicleDisclosure`,
      data: {
        answers,
        partner_id: partnerId,
        zip: zipCode,
      },
    };

    return fetch(config);
  };

  return [fetchVehiclePrice, fetchRes];
};
