import { useMemo } from 'react';

import { useFetch } from '@portal/hooks';
import { VehicleTaxonomyAuthorization, ServiceUrl } from '@portal/lib';
import { formatList, GenerateMakeURL } from '@portal/lead/utils';

export const useVehicleTaxonomy = ({
  errorText,
  isErrorAlertMuted = false,
  onError,
  onSuccess,
} = {}) => {
  const [fetchListOptions, fetchListOptionResponse] = useFetch({
    baseURL: ServiceUrl,
    errorText,
    isErrorAlertMuted,
    onSuccess: (data) => {
      onSuccess?.(data);
    },
    onError: (error) => {
      onError?.(error);
    },
  });

  const getDrilldown = (yearList) => {
    const makeList =
      yearList && yearList.length > 0 ? yearList[0]?.make_list : [];

    const drillDown = makeList.reduce((makes, makeInfo) => {
      const modelList = makeInfo.model_list;

      makes[makeInfo.make_name] = modelList?.reduce((models, modelInfo) => {
        const seriesList = modelInfo.series_list;

        models[modelInfo.model_name] = seriesList?.reduce(
          (series, seriesInfo) => {
            const stylesList = seriesInfo.style_list;
            const seriesName = seriesInfo.series_name
              ? seriesInfo.series_name
              : 'None';

            series[seriesName] = stylesList?.reduce((styles, styleInfo) => {
              styles[styleInfo.style_name] = styleInfo.squish_vin_list[0];
              return styles;
            }, {});
            return series;
          },
          {}
        );
        return models;
      }, {});
      return makes;
    }, {});
    return drillDown;
  };

  const vehicleDrillDown = useMemo(
    () => getDrilldown(fetchListOptionResponse?.data?.data?.year_list),
    [fetchListOptionResponse?.data?.data?.year_list]
  );

  const getMakeList = () =>
    vehicleDrillDown ? formatList(vehicleDrillDown) : [];

  const getModelList = (make) =>
    vehicleDrillDown?.[make] ? formatList(vehicleDrillDown[make]) : [];

  const getTrimList = (make, model) =>
    vehicleDrillDown?.[make]?.[model]
      ? formatList(vehicleDrillDown[make][model])
      : [];

  const getStyleList = (make, model, trim) =>
    vehicleDrillDown?.[make]?.[model]?.[trim]
      ? formatList(vehicleDrillDown[make][model][trim])
      : [];

  const getSquishVin = (make, model, trim, style) =>
    vehicleDrillDown?.[make]?.[model]?.[trim]?.[style] ?? undefined;

  const fetchVehicleTaxonomy = async (year = '') => {
    try {
      const url = `/GetVehicleTaxonomyByFilter${GenerateMakeURL(year)}`;
      const result = await fetchListOptions({
        url,
        headers: {
          authorization: VehicleTaxonomyAuthorization,
        },
      });

      return {
        data: getDrilldown(result.data.year_list),
        error: null,
      };
    } catch (error) {
      console.error('error', error);
      return {
        data: null,
        error: error,
      };
    }
  };

  return {
    error: fetchListOptionResponse?.error,
    fetchVehicleTaxonomy,
    getMakeList,
    getModelList,
    getSquishVin,
    getStyleList,
    getTrimList,
    isLoading: fetchListOptionResponse?.isLoading,
    vehicleDrillDown,
  };
};
