export const AcvAuctionsUrl = process.env.NEXT_PUBLIC_ACV_AUCTIONS_URL;
export const AnalyticsApiKey = process.env.NEXT_PUBLIC_ANALYTICS_API_KEY;
export const AnalyticsIsEnabled =
  process.env.NEXT_PUBLIC_ANALYTICS_IS_ENABLED === 'true';
export const AppTheme = process.env.NEXT_PUBLIC_APP_THEME;
export const HasuraUrl = process.env.NEXT_PUBLIC_HASURA_URL;
export const HasuraWebsocketUrl = process.env.NEXT_PUBLIC_HASURA_WS_URL;
export const InspectionUrl = process.env.NEXT_PUBLIC_INSPECTION_URL;
export const LogEnv = process.env.NEXT_PUBLIC_LOG_ENV;
export const LogKey = process.env.NEXT_PUBLIC_LOG_KEY;
export const LogEnabled = process.env.NEXT_PUBLIC_LOG_ENABLED === 'true';
export const MonkMessageSender = process.env.NEXT_PUBLIC_MONK_MESSAGE_SENDER;
export const ServiceUrl = process.env.NEXT_PUBLIC_REST_API;
export const UsedCarClubUrl = process.env.NEXT_PUBLIC_USED_CAR_CLUB_URL;
export const IsDevEnvironment = process.env.NODE_ENV === 'development';
export const LoggerAppSourceName = 'clearcar-portal';
export const VehicleTaxonomyAuthorization =
  process.env.NEXT_PUBLIC_VEHICLE_TAXONOMY_AUTHORIZATION;
export const AutoCheckAuthorization =
  process.env.NEXT_PUBLIC_AUTO_CHECK_AUTHORIZATION;
