import { useState, useRef } from 'react';
import {
  Box,
  Button,
  Chip,
  DateTimePicker,
  IconExpandMore,
  Popover,
} from '@drivably-apps/component-lib';

import { useMutation } from '@portal/hooks';
import {
  DefaultStatusFlags,
  getStatusOptions,
  getVehicleStatus,
  VehicleStatus,
} from '@portal/lib';
import {
  VehicleLeadSearchGql,
  VehicleLeadSearchNoTermGql,
  VehicleUpdateStatusGql,
  VehicleCreateMeetingGql,
  VehicleUpdateMeetingGql,
  VehicleLeadsCountWithSearchGql,
  VehicleLeadsCountWithoutSearchGql,
} from '@portal/query/graphql';

const ButtonColorMap = {
  success: 'primary-success',
  info: 'primary',
};

const LeadListStatusWithDropdown = ({
  hasSearchTerm,
  id,
  status,
  pastMeetingVehicleId,
}) => {
  const popoverTargetRef = useRef(null);

  const [draftStatus, setDraftStatus] = useState('');
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const [updateStatus, updateStatusRes] = useMutation(VehicleUpdateStatusGql, {
    isSuccessAlertMuted: true,
    refetchQueries: hasSearchTerm
      ? [VehicleLeadSearchGql, VehicleLeadsCountWithSearchGql]
      : [VehicleLeadSearchNoTermGql, VehicleLeadsCountWithoutSearchGql],
    onCompleted: () => setDraftStatus(''),
  });

  const [createMeeting, createMeetingRes] = useMutation(
    VehicleCreateMeetingGql,
    { isSuccessAlertMuted: true }
  );

  const [updateMeeting, updateMeetingRes] = useMutation(
    VehicleUpdateMeetingGql,
    { isSuccessAlertMuted: true }
  );

  const updateStatusByValue = (value) => {
    updateStatus({
      variables: {
        vehicle_id: Number(id),
        _set: {
          ...DefaultStatusFlags,
          [value]: true,
        },
      },
    });
  };

  const updateMeetingByValue = (date) => {
    // Persist the selected date
    const meetingPayload = {
      variables: {
        vehicle_id: Number(id),
        date,
      },
    };

    if (pastMeetingVehicleId) {
      updateMeeting(meetingPayload);
    } else {
      createMeeting(meetingPayload);
    }
  };

  const handleStatusClick = (updatedStatus) => {
    setIsStatusDropdownOpen(false);

    if (updatedStatus === VehicleStatus.BookedVisit) {
      setDraftStatus(updatedStatus);
      setIsDatePickerOpen(true);
    } else {
      // Reset the date when changing from booked visit to non-booked visit
      updateMeetingByValue(null);

      updateStatusByValue(updatedStatus);
    }
  };

  const handleDatePickerConfirm = (date) => {
    setIsDatePickerOpen(false);

    // Save the selected date
    updateMeetingByValue(date);

    // Update status using drafted value
    updateStatusByValue(draftStatus);
  };

  const handleDatePickerClose = () => {
    setIsDatePickerOpen(false);
    setDraftStatus('');
  };

  // If there is a draft status like booked visit, use it.
  const currentStatus = draftStatus || status;

  const { color, text } = getVehicleStatus(currentStatus, ButtonColorMap);
  const options = getStatusOptions(currentStatus);

  const renderedOptions = options.map(({ text, color, value }) => (
    <Chip
      borderRadius="sm"
      color={color}
      key={value}
      onClick={() => handleStatusClick?.(value)}
      size="small"
      variant="clear"
    >
      {text}
    </Chip>
  ));

  const isLoading =
    updateStatusRes.isLoading ||
    createMeetingRes.isLoading ||
    updateMeetingRes.isLoading;

  return (
    <>
      <Button
        color={color}
        forwardedRef={popoverTargetRef}
        isCapitalCase
        isLoading={isLoading}
        isRounded
        onClick={() => setIsStatusDropdownOpen(true)}
        size="xs"
        variant="contained"
      >
        {text}
        <IconExpandMore color="light" marginLeft="xs" size="sm" />
      </Button>
      <Popover
        isHorizontal={false}
        isOpen={isStatusDropdownOpen}
        onClose={() => setIsStatusDropdownOpen(false)}
        target={popoverTargetRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        marginTop="md"
      >
        <Box
          direction="column"
          flexGap="md"
          paddingHorizontal="md"
          paddingVertical="lg"
          align="flex-start"
        >
          {renderedOptions}
        </Box>
      </Popover>
      <DateTimePicker
        isOpen={isDatePickerOpen}
        isPastDisabled
        onClose={handleDatePickerClose}
        onConfirm={handleDatePickerConfirm}
        target={popoverTargetRef.current}
      />
    </>
  );
};

export default LeadListStatusWithDropdown;
