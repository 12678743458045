import * as dayjs from 'dayjs';
import { DateFormats } from './constants';

export const formatDate = (date = '', format = '') => {
  return dayjs(date).format(format);
};

export const isBeforeDate = (sourceDate = '', targetDate = '') => {
  return dayjs(sourceDate).isBefore(targetDate);
};

export const diffDate = (sourceDate = '', targetDate = '') => {
  const firstDate = dayjs(sourceDate);
  const secondDate = dayjs(targetDate);
  return firstDate.diff(secondDate);
};

export const addDaysToDate = (currentDate, days = 0) => {
  return new Date(currentDate).setDate(new Date(currentDate).getDate() + days);
};

export const currentDate = () => {
  return new Date().toLocaleDateString('en-US');
};

export const handleDate = (date, format = DateFormats.mm_dd_yy) =>
  !!date ? formatDate(date, format) : '––';

export const getDayJSObject = (date) => (date ? dayjs(date) : null);

export const getISODate = (date) => dayjs(date).toISOString();
