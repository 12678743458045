import { Input } from '@drivably-apps/component-lib';
import { useField } from 'formik';

const InputField = ({ helpText, ...props }) => {
  const [field, meta] = useField(props);
  const errorMessage = meta.touched && meta.error;

  return <Input {...field} {...props} errorText={errorMessage || helpText} />;
};

export default InputField;
