import { leadsTabTypes, statusTypes } from './constants';

const hasTruthy = { _eq: true };
const hasFalsy = { _eq: false };

const getStatusFilterCondition = (status) => {
  const includesClosedWon = status?.includes(statusTypes.ClosedWon);
  const includesClosedLost = status?.includes(statusTypes.ClosedLost);

  if (includesClosedWon) {
    return [{ closed_won: { _eq: true } }];
  } else if (includesClosedLost) {
    return [{ closed_lost: { _eq: true } }];
  } else {
    return [
      { closed_won: { _eq: includesClosedWon || true } },
      { closed_lost: { _eq: !includesClosedLost || true } },
    ];
  }
};

export const getLeadsQueryForTab = (tab, status = []) => {
  const tabQueries = {
    [leadsTabTypes.Active]: {
      _and: [
        { closed_won: hasFalsy },
        { closed_lost: hasFalsy },
        { booked_visit: hasFalsy },
        {
          _or: [{ new: hasTruthy }],
        },
      ],
    },
    [leadsTabTypes.Archive]: {
      _or: getStatusFilterCondition(status),
    },
    [leadsTabTypes.Appointments]: {
      _or: [{ booked_visit: hasTruthy }],
    },
  };

  return tabQueries[tab] || null;
};
