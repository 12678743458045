import { forwardRef } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

import Icon, { setIconColor } from './Icon';
import { styled } from './lib';

const ButtonColor = {
  Primary: 'primary',
  Secondary: 'secondary',
};

export const ButtonVersion = {
  Primary: 'primary',
  Secondary: 'secondary',
  TextLink: 'textLink',
  Tertiary: 'tertiary',
  Warning: 'warning',
};

const ButtonSize = {
  Lg: 'lg',
  Md: 'md',
};

export const ButtonVersionVariantMap = {
  [ButtonVersion.Primary]: 'contained',
  [ButtonVersion.Secondary]: 'outlined',
  [ButtonVersion.TextLink]: 'text',
  [ButtonVersion.Tertiary]: 'contained',
  [ButtonVersion.Warning]: 'contained',
};

const Button = forwardRef(
  (
    {
      alignSelf,
      color = ButtonColor.Primary,
      flexBasis,
      flexGrow,
      flexShrink,
      isDisabled = false,
      isLoading = false,
      isUpperCase = true,
      size = ButtonSize.Md,
      startIcon = '',
      colorIcon,
      version = ButtonVersion.Primary,
      width,
      marginTop,
      isRounded = false,
      isContained = true,
      ...props
    },
    ref
  ) => (
    <LoadingButtonStyled
      {...props}
      ref={ref}
      color={color}
      disabled={isDisabled}
      isUpperCase={isUpperCase}
      loading={isLoading}
      startIcon={
        startIcon && <Icon size="sm" icon={startIcon} color={colorIcon} />
      }
      variant={ButtonVersionVariantMap[version]}
      version={version}
      size={size}
      sx={{
        alignSelf,
        flexBasis,
        flexGrow,
        flexShrink,
        width,
        marginTop,
      }}
      isRounded={isRounded}
      isContained={isContained}
    />
  )
);

export default Button;

const LoadingButtonStyled = styled(LoadingButton, {
  shouldForwardProp: (name) =>
    !['isUpperCase', 'isRounded', 'isContained', 'version'].includes(name),
})`
  padding: 16px;
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }

  ${({ isRounded }) => `border-radius : ${isRounded ? '100px;' : '6px;'}`}
  ${({ size }) => {
    const map = {
      [ButtonSize.Lg]: '56px',
      [ButtonSize.Md]: '46px',
    };

    return `height: ${map[size]};`;
  }}

  ${({ theme }) => theme.typography.GlobalText.button}
  ${({ isUpperCase }) => isUpperCase && { textTransform: 'uppercase' }}
  ${({ theme, version, color, isDarkBlue, isContained }) => {
    const map = {
      [ButtonVersion.Primary]: !!isDarkBlue
        ? `
         &:hover {
           background-color: ${theme.color.Drivably.secondaryColorDark}80;
         }
         background-color: ${theme.color.Drivably.secondaryColorDark};
       `
        : {
            background: theme.color.Drivably.primaryColor,
          },
      [ButtonVersion.Secondary]:
        color === ButtonColor.Primary
          ? `
              border: 1px solid ${theme.color.Drivably.primaryColor};
              color: ${theme.color.Drivably.primaryColor};

              &:hover {
                border-color: ${theme.color.Drivably.primaryColor};
              }
            `
          : `
              border: 1px solid ${theme.color.backgrounds.borders};
              color: ${theme.color.text.text3};

              &:hover {
                border-color: ${theme.color.backgrounds.borders};
              }
            `,
      [ButtonVersion.TextLink]: `
        color: ${
          !!isDarkBlue
            ? theme.color.ACVTextLm.textPrimaryColor
            : theme.color.Drivably.secondaryColorDark
        };
        .Button-startIcon > div {
          ${setIconColor({ color: 'secondary', theme })}
        }
      `,
      [ButtonVersion.Tertiary]: `
        &:hover {
          background-color: ${theme.color.backgrounds.gray}80;
        }
        background-color: ${theme.color.backgrounds.gray};
      `,

      [ButtonVersion.Warning]: `
        border: 1px solid ${theme.color.ACVPrimaryShadesLm.primaryColorBase};
        background-color: ${
          isContained
            ? theme.color.ACVPrimaryShadesLm.primaryColorBase
            : theme.color.raw.white
        };
        color : ${
          isContained
            ? theme.color.raw.white
            : theme.color.ACVPrimaryShadesLm.primaryColorBase
        };
        &:hover {
          background-color: ${
            theme.color.ACVPrimaryShadesLm.primaryColorBase
          }80;
        }
      `,
    };

    return map[version];
  }}
`;
