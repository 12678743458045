import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { styled } from './lib';
import Icon from './Icon';
import LoaderCircle from './LoaderCircle';

const Input = ({
  endIcon = '',
  flexGrow,
  isAutoComplete = true,
  isLoading = false,
  isSpellChecked = true,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY,
  minWidth,
  startIcon = '',
  rotateIcon = '0',
  width,
  textAlign = 'left',
  background,
  ...props
}) => {
  const getEndAdornmentProps = () => {
    let content = null;

    if (isLoading) {
      content = <LoaderCircle size="sm" />;
    } else if (endIcon) {
      content = <Icon icon={endIcon} rotate={rotateIcon} />;
    }

    return (
      content && {
        endAdornment: <InputAdornment position="end">{content}</InputAdornment>,
      }
    );
  };

  return (
    <TextFieldStyled
      {...props}
      variant="filled"
      textalign={textAlign}
      background={background}
      sx={{
        flexGrow,
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        marginX,
        marginY,
        minWidth,
        width,
      }}
      InputProps={{
        ...(startIcon && {
          startAdornment: (
            <InputAdornment position="start">
              <Icon icon={startIcon} />
            </InputAdornment>
          ),
        }),
        ...getEndAdornmentProps(),
        autoComplete: isAutoComplete ? 'on' : 'off',
        spellCheck: isSpellChecked ? 'true' : 'false',
      }}
    />
  );
};

export default Input;

const TextFieldStyled = styled(TextField)`
  .MuiInputLabel-root {
    width: ${(props) => props.width || 'unset'};
    ${({ theme }) => theme.typography.GlobalText.bodyBaseRegular};

    &:not(.Mui-error) {
      color: ${({ theme }) => theme.color.ACVTextLm.textSecondaryColor};

      &.Mui-focused {
        color: ${({ theme }) => theme.color.Drivably.primaryColor};
      }
    }
  }

  .MuiInputBase-input {
    background: ${(props, theme) =>
      props?.background || theme?.color?.ACVBgLm?.background1Color};
  }
  .MuiInputBase-root {
    background: ${(props, theme) =>
      props?.background || theme?.color?.ACVBgLm?.background1Color};
    border: ${(props) => (props?.isNoBorder ? 0 : 'initial')};
    ${({ theme }) => theme.typography.GlobalText.bodyBaseRegular};

    &:before {
      border: 0;
    }

    &:hover:before,
    &:not(.Mui-disabled):hover:before {
      border: 0;
    }

    &:not(.Mui-error) {
      &:after {
        border-bottom-color: ${({ theme }) =>
          theme.color.Drivably.primaryColor};
      }
    }
  }

  ${({ label, textalign }) =>
    !label &&
    `.MuiInputBase-input {
      text-align: ${textalign};
      padding-top: 16.5px;
      padding-bottom: 16.5px;
    }`}
`;
