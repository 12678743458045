import { useFormikContext } from 'formik';

/**
 * Wrap formik context with enhanced validation methods. See list of available
 * props here: https://formik.org/docs/api/formik#props-1.
 */
export const useFormContext = () => {
  const formik = useFormikContext();
  const { errors, values, setFieldTouched } = formik;

  /** Accept a list of field names. Validate each field if needed. */
  const validateFieldList = (fieldNamesList = []) => {
    let isListValid = true;

    fieldNamesList.forEach((fieldName) => {
      const isValid =
        !errors[fieldName] &&
        (values[fieldName] !== undefined || values[fieldName] !== null);

      if (!isValid) {
        isListValid = false;
        setFieldTouched(fieldName, true, true);
      }
    });

    return isListValid;
  };

  return {
    ...formik,
    validateFieldList,
  };
};
