import { useRef, useState } from 'react';
import {
  Badge,
  ButtonIcon,
  IconNotification,
  Popover,
  Box,
  Label,
  Button,
  Text,
  useDetectDevice,
} from '@drivably-apps/component-lib';
import { useLocale } from '@portal/hooks';
import { Notification as NotificationList } from '@portal/notifications';
import { boxProp } from '@portal/notifications/lib';
import { useRouter } from 'next/router';
import { Route } from '@portal/lib';

const RenderNoNotifications = ({ boxProp, message }) => {
  return <Box {...boxProp}>{message}</Box>;
};

const NotificationPopover = ({ notificationCount, notifications }) => {
  const popoverTargetRef = useRef(null);
  const { locale } = useLocale();
  const router = useRouter();
  const { isMobile } = useDetectDevice();

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const openNotificationPage = () => {
    router.push(Route.notificationListPage.path);
  };

  const emptyNotifications = notifications?.length === 0;
  return (
    <Box>
      <ButtonIcon
        forwardedRef={popoverTargetRef}
        onClick={() => setIsNotificationOpen(true)}
      >
        <Badge
          notificationCount={notificationCount}
          color="warning"
          verticalAlignment="bottom"
          horizontalAlignment="right"
        >
          <IconNotification height="24" width="24" />
        </Badge>
      </ButtonIcon>
      <Popover
        isHorizontal={false}
        isOpen={isNotificationOpen}
        onClose={() => setIsNotificationOpen(false)}
        target={popoverTargetRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        PaperProps={{
          style: {
            width: isMobile ? '90vw' : '25vw',
            ...(isMobile && { left: '100px' }),
            ...(isMobile && { left: '47px' }),
          },
        }}
        marginTop="md"
      >
        <Box direction="column" flexGap="md" align="flex-start" padding="lg">
          <Box isFlex justify="space-between" width="full">
            <Label weight="bold" color="gray-text">
              {locale.notifications}
            </Label>
            {!emptyNotifications && (
              <Button
                isRounded
                isCapitalCase
                size="xs"
                onClick={openNotificationPage}
              >
                <Text color="light">{locale.seeAllNotifications}</Text>
              </Button>
            )}
          </Box>
          {!!emptyNotifications ? (
            <RenderNoNotifications
              boxProp={boxProp}
              message={locale.noNotifications}
            />
          ) : (
            <NotificationList
              data={notifications}
              showPagination={false}
              showPanelNotification
            />
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default NotificationPopover;
