import {
  exportFilterDate,
  exportFilterAppointmentStatus,
  exportFilterValidValue,
  diffDate,
  caseInsensitive,
  sortFloating,
} from '@portal/lib';
import AppointmentListStatus from './AppointmentListStatus';

export const getAppointmentListColumns = ({ locale }) => [
  {
    field: 'created_at',
    headerName: locale.appointmentTableCreatedAt,
    exportFilter: exportFilterDate,
    comparator: diffDate,
  },
  {
    field: 'status',
    sortable: false,
    headerName: locale.appointmentTableStatus,
    cellRenderer: ({ value }) => (
      <AppointmentListStatus
        isUpcoming={value}
        upcomingText={locale.appointmentStatusUpcoming}
        doneText={locale.appointmentStatusDone}
      />
    ),
    exportFilter: exportFilterAppointmentStatus,
  },
  {
    field: 'contact__name',
    headerName: locale.appointmentTableCustomerName,
    comparator: caseInsensitive,
    maxWidth: 200,
  },

  {
    field: 'contact__mobile',
    headerName: locale.appointmentTablePhone,
    maxWidth: 170,
  },
  {
    field: 'vin',
    headerName: locale.appointmentTableVIN,
    style: { textTransform: 'uppercase' },
    exportFilter: exportFilterValidValue,
  },
  {
    field: 'year',
    headerName: locale.appointmentTableYear,
    exportFilter: exportFilterValidValue,
  },
  {
    field: 'make',
    headerName: locale.appointmentTableMake,
    exportFilter: exportFilterValidValue,
    comparator: caseInsensitive,
  },
  {
    field: 'model',
    headerName: locale.appointmentTableModel,
    exportFilter: exportFilterValidValue,
    comparator: caseInsensitive,
  },
  {
    field: 'trim',
    headerName: locale.appointmentTableTrim,
    exportFilter: exportFilterValidValue,
    comparator: caseInsensitive,
  },
  {
    field: 'store__name',
    headerName: locale.appointmentTableStore,
    exportFilter: exportFilterValidValue,
    comparator: caseInsensitive,
  },
  {
    field: 'mi',
    headerName: locale.appointmentTableOdometer,
    comparator: sortFloating,
  },
  {
    field: 'moved_to_appointment_at',
    bdField: 'moved_to_appointment_at',
    headerName: locale.appointmentTableAppointmentDate,
    flex: 1,
    minWidth: 160,
    exportFilter: exportFilterDate,
  },
];
