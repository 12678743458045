import { Alert } from '@mui/material';
import Text from './Text';

const ChipAlert = ({ icon, message, ...props }) => (
  <Alert icon={icon} severity="success" {...props}>
    <Text type="caption">{message}</Text>
  </Alert>
);

export default ChipAlert;
