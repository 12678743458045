import { useAlert } from './useAlert';
import { useLocale } from './useLocale';

export const useClipboard = () => {
  const { locale } = useLocale();
  const { setSuccessAlert } = useAlert();

  const copyToClipboard = (value, { successText } = {}) => {
    navigator.clipboard.writeText(value);
    setSuccessAlert(successText || locale.appClipboardCopySuccess);
  };

  return { copyToClipboard };
};
