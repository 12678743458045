import cls from 'clsx';
import { Field } from 'formik';
import { Box, Checkbox } from '@drivably-apps/component-lib';

import { hasArrayValues } from '@portal/lib';
import { styled } from '@portal/ui/lib';

const CheckboxField = ({ value, highlightedDefault, ...props }) => (
  <Box
    isFlex
    justify="space-between"
    direction="row"
    align="center"
    flexGap="sm"
  >
    <Field type="checkbox" value={value} {...props}>
      {({ field, meta }) => {
        let isValueSelected = false;
        let shouldValueHighlighted = false;

        if (hasArrayValues(highlightedDefault)) {
          shouldValueHighlighted = highlightedDefault.indexOf(field.value) >= 0;
        }
        if (hasArrayValues(meta.value)) {
          isValueSelected = meta.value.indexOf(field.value) >= 0;
        }

        return (
          <StyledCheckbox
            {...field}
            label={value}
            defaultChecked={field.checked}
            className={cls({
              'walk-around-modal-checkbox-selected': isValueSelected,
              'walk-around-modal-checkbox-lead-selection':
                shouldValueHighlighted,
              'walk-around-modal-checkbox-lead-selection-default':
                !isValueSelected && shouldValueHighlighted,
            })}
          />
        );
      }}
    </Field>
  </Box>
);

export default CheckboxField;

const StyledCheckbox = styled(Checkbox)`
  &.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
    &.walk-around-modal-checkbox-selected > span:not(.MuiTypography-root) {
      color: ${({ theme }) => theme.color.ACVPrimaryShadesLm.primaryColorBase};
      border: none;
      &:hover {
        color: ${({ theme }) =>
          theme.color.ACVPrimaryShadesLm.primaryColorBase}B3;
        border: none;
      }

      & > span:not(.MuiTypography-root):not(.MuiTouchRipple-root) {
        background-color: ${({ theme }) =>
          theme.color.ACVPrimaryShadesLm.primaryColorBase};
      }
    }

    &.walk-around-modal-checkbox-lead-selection
      > span:not(.MuiTypography-root) {
      color: ${({ theme }) => theme.color.Drivably.primaryColor};
      &:hover {
        color: ${({ theme }) => theme.color.Drivably.primaryColor}B3;
      }

      & > span:not(.MuiTypography-root):not(.MuiTouchRipple-root) {
        background-color: ${({ theme }) => theme.color.Drivably.primaryColor};
      }
    }
  }

  &.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
    &.walk-around-modal-checkbox-lead-selection-default
      > span:not(.MuiTypography-root) {
      color: red;
      color: ${({ theme }) => theme.color.Drivably.primaryColor}1F;

      & > span:not(.MuiTypography-root):not(.MuiTouchRipple-root) {
        background-color: ${({ theme }) => theme.color.Drivably.primaryColor}1F;
      }
    }
  }
`;
