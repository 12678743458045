import { styled } from './lib';

const Svg = ({
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY,
  ...props
}) => (
  <SvgStyled
    {...props}
    sx={{
      margin,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      marginX,
      marginY,
    }}
  />
);

export default Svg;

const SvgStyled = styled('svg')(() => ({}));
