import { setLocale } from 'yup';

export const configureYup = () => {
  setLocale({
    mixed: {
      default: 'This field is invalid.',
      required: 'This field is required.',
    },
    string: {
      email: 'Invalid email address.',
    },
  });
};
