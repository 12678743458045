import { useState, useEffect } from 'react';

import { UserFindByIdGql } from '@portal/query/graphql';
import { useLocale, useAlert } from '@portal/hooks';

const UserDefaultState = {
  meta: {},
};

/** Fetch current user when user id is available */
export const useUser = ({
  client,
  isPublicRoute,
  userId,
  onError,
  onSuccess,
}) => {
  const { locale } = useLocale();
  const { setErrorAlert } = useAlert();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleFetchUserError = () => {
      setUser(UserDefaultState);
      setErrorAlert(locale.appUserFailed);
      onError?.();
    };

    const fetchUser = async () => {
      setIsLoading(true);

      try {
        const { data, error } = await client.query({
          query: UserFindByIdGql,
          variables: { id: userId },
        });

        if (error) {
          handleFetchUserError();
          return;
        }

        const userData = data.users[0];

        const hasManagedStores =
          Array.isArray(userData.managed_stores) &&
          userData.managed_stores.some(
            ({ store }) =>
              Array.isArray(store?.vehicles) && !!store.vehicles.length
          );

        const hasAppraisalStores = userData.portal_roles.some(
          ({ store }) => store?.vehicles_aggregate?.aggregate?.count > 0
        );

        const storeList = userData.portal_roles.map(({ store }) => ({
          id: store.id,
          name: store.name,
        }));

        const getValidStore = (stores) => {
          if (Array.isArray(stores)) {
            const filtered = stores.filter(({ store }) => Boolean(store.id));
            return filtered?.[0]?.store;
          }

          return null;
        };

        const firstManagedStore = getValidStore(userData.managed_stores);
        const firstPortalStore = getValidStore(userData.portal_roles);

        // Set first store as current
        const currentStore = firstManagedStore || firstPortalStore || {};

        // Provide metadata for quick references
        const meta = {
          currentStore,
          hasAppraisalStores,
          hasManagedStores,
          storeList,
          clientInstance: client,
        };

        setUser({ ...userData, meta });
        onSuccess?.();
      } catch (e) {
        console.error(e);
        handleFetchUserError();
      }

      setIsLoading(false);
    };

    if (client && userId && !isPublicRoute) {
      fetchUser();
    }
  }, [client, userId, isPublicRoute]);

  return { data: user, isLoading, client };
};
