import cls from 'clsx';
import { Field } from 'formik';
import { Button } from '@drivably-apps/component-lib';

import { hasArrayValues } from '@portal/lib';
import { styled } from '@portal/ui/lib';

const ButtonField = ({ text, highlightedDefault, callback, ...props }) => {
  return (
    <Field {...props}>
      {({ field, form, meta }) => {
        let isValueSelected = false;
        const isCheckbox = props.type === 'checkbox';

        if (isCheckbox && hasArrayValues(meta.value)) {
          isValueSelected = meta.value.includes(field.value);
        } else {
          isValueSelected = meta.value === field.value;
        }

        return (
          <StyledButton
            variant={isValueSelected ? 'contained' : 'outlined'}
            size="xs"
            color={isValueSelected ? 'accent' : 'secondary'}
            {...field}
            className={cls({
              'walk-arround-modal-button-selected': isValueSelected,
              'walk-arround-modal-button-lead-selection': highlightedDefault,
              'walk-arround-modal-button-lead-selection-default':
                !isValueSelected && highlightedDefault,
            })}
            onClick={() => {
              if (isCheckbox) {
                let formValue = Object.assign([], meta.value);
                const valueIndex = formValue?.indexOf(field.value) ?? -1;

                if (hasArrayValues(formValue) && valueIndex === -1) {
                  formValue = [...formValue, field.value];
                } else if (hasArrayValues(formValue) && valueIndex > -1) {
                  formValue.splice(valueIndex, 1);
                } else {
                  formValue = [field.value];
                }

                form.setFieldValue(props.name, formValue);
              } else {
                if (callback) {
                  callback(field.value);
                }

                form.setFieldValue(props.name, field.value);
              }
            }}
          >
            {text}
          </StyledButton>
        );
      }}
    </Field>
  );
};

export default ButtonField;

const StyledButton = styled(Button)`
  &.MuiButtonBase-root.MuiButton-text.MuiButton-textPrimary {
    &.walk-arround-modal-button-selected {
      background-color: ${({ theme }) =>
        theme.color.ACVPrimaryShadesLm.primaryColorBase};
      border: none;
      &:hover {
        background-color: ${({ theme }) =>
          theme.color.ACVPrimaryShadesLm.primaryColorBase}B3;
        border: none;
      }
    }

    &.walk-arround-modal-button-lead-selection {
      background-color: ${({ theme }) => theme.color.Drivably.primaryColor};
      &:hover {
        background-color: ${({ theme }) => theme.color.Drivably.primaryColor}B3;
      }
    }
  }

  &.MuiButtonBase-root.MuiButton-text.MuiButton-textPrimary.MuiButton-root {
    &.walk-arround-modal-button-lead-selection-default {
      background-color: ${({ theme }) => theme.color.Drivably.primaryColor}14;
    }
  }
`;
