import { gql } from '@apollo/client';

export const DisclosureCreateGql = gql`
  mutation DisclosureCreate(
    $appraisal: Appraisal!
    $disclosure: Disclosures!
    $vehicle_uuid: uuid!
  ) {
    addDisclosure(
      appraisal: $appraisal
      disclosure: $disclosure
      vehicle_uuid: $vehicle_uuid
    ) {
      appraisal_id
      disclosure_id
      vehicle_id
    }
  }
`;
