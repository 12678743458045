import { Flex, Status } from '@portal/ui';

const AppointmentListStatus = ({ isUpcoming, upcomingText, doneText }) => (
  <Flex spacing="5px">
    {isUpcoming ? (
      <Status type="info" label={upcomingText} />
    ) : (
      <Status type="success" label={doneText} />
    )}
  </Flex>
);

export default AppointmentListStatus;
