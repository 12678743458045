import { getAppointmentListColumns } from '../appointment/AppointmentListColumns';
import { getAppraiserListColumns } from '../appraisal/AppraisalListColumns';
import { getAdditionalLeadListColumns } from '../lead/LeadsListColumns';
import { pageTypes } from './constants';
import { parsePathDashed } from './recursive';

const columnsByType = {
  [pageTypes.Leads]: getAdditionalLeadListColumns,
  [pageTypes.Appraisals]: getAppraiserListColumns,
  [pageTypes.Appointment]: getAppointmentListColumns,
};

export const exportCSV = ({ type, data, locale }) => {
  const columns = columnsByType[type]({ locale }) || [];
  const fieldsColumns = columns.map(
    (el) => `"${el.headerName || el.bdField || el.field}"`
  );
  const vehiclesExport = data.flatMap((vehicleItem) => {
    const fields = columns.map((el) => {
      const field = el.bdField || el.field;
      let returnValue = parsePathDashed(vehicleItem, field);
      if (el.exportFilter) {
        returnValue = el.exportFilter({ value: returnValue, locale });
      }

      return returnValue;
    });

    return fields.join();
  });

  return `${fieldsColumns}\n${vehiclesExport.join('\n')}`;
};

export const downloadCSV = (data, name = 'csv') => {
  const stampTime = new Date().getTime();
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', `${name}_${stampTime}.csv`);
  a.click();
};
