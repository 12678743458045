import * as dayjs from 'dayjs';
import { truncateWithEllipsis } from '@portal/lib';
import { locale } from '@portal/locale';

export const getVehicleName = ({ year, make, model, trim }) => {
  return truncateWithEllipsis(
    `${year || ''} ${make || ''} ${model || ''} ${trim || ''}`,
    35
  );
};

export const getTimeDiff = (notification_timestamp) => {
  const currentTime = dayjs();
  const notificationTime = dayjs(notification_timestamp);
  return currentTime.diff(notificationTime, 'minute');
};

export const isNewNotification = (timestamp) => {
  const diffInMinutes = getTimeDiff(timestamp);
  return diffInMinutes <= 60;
};

export const generateNotificationTime = (notification_timestamp) => {
  const diffInMinutes = getTimeDiff(notification_timestamp);
  const diffInHours = Math.floor(diffInMinutes / 60);

  //if notification was less than an hour ago
  if (diffInMinutes < 60) {
    return locale.formatString(locale.notificationTimeMinute, diffInMinutes);
  }

  //if notification was a 12 hour ago
  else if (diffInHours <= 12) {
    return locale.formatString(locale.notificationTimeHour, diffInHours);
  }

  //if notification was 24 hour ago
  else if (diffInHours > 12 && diffInHours < 24) {
    return locale.notificationTimeDay;
  }

  //else return notification date
  else {
    const notificationTime = dayjs(notification_timestamp);
    const time = notificationTime.format('h:mm a');
    const date = notificationTime.format('M/D');
    return locale.formatString(locale.notificationDate, time, date);
  }
};

export const getNewEarlyList = (data) => {
  const list = { newList: [], earlierList: [] };
  if (data && Array.isArray(data)) {
    for (let item of data) {
      if (isNewNotification(item.created_at)) {
        list.newList.push(item);
      } else {
        list.earlierList.push(item);
      }
    }
  }
  return list;
};

export const getUnreadCount = (data) => {
  return data?.filter((item) => !item?.read);
};
