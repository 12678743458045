import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useSessionStorage } from './useSessionStorage';

export const usePreviousPath = () => {
  const router = useRouter();
  const [currentPath, setCurrentPath] = useSessionStorage('currentPath', '');
  const [prevPath, setPrevPath] = useSessionStorage('prevPath', '');

  useEffect(() => {
    setPrevPath(currentPath);
    setCurrentPath(location.pathname);
  }, [router.asPath]);

  return {
    isRootPath: prevPath === currentPath,
    prevPath,
  };
};
