import IconChevronLeft from '@mui/icons-material/ChevronLeft';
import IconChevronRight from '@mui/icons-material/ChevronRight';
import IconSearch from '@mui/icons-material/Search';
import IconSend from '@mui/icons-material/Send';
import IconArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import IconFavorite from '@mui/icons-material/Favorite';
import IconShare from '@mui/icons-material/Share';
import IconMoreHoriz from '@mui/icons-material/MoreHoriz';
import IconContentCopy from '@mui/icons-material/ContentCopy';
import IconMenu from '@mui/icons-material/Menu';
import IconClose from '@mui/icons-material/Close';
import IconPhoto from '@mui/icons-material/Photo';
import IconCloudDownload from '@mui/icons-material/CloudDownload';
import IconCheck from '@mui/icons-material/Check';
import IconOpenInNew from '@mui/icons-material/OpenInNew';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconDealer from './icons/Dealer';
import IconExpandLess from '@mui/icons-material/ExpandLess';
import IconExpandMore from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DoneIcon from '@mui/icons-material/Done';
import RefreshIcon from '@mui/icons-material/Refresh';
import PrintIcon from '@mui/icons-material/Print';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import { styled } from './lib';
import Flex from './Flex';

const IconName = {
  ArrowBack: 'arrowBack',
  ChevronLeft: 'chevronLeft',
  ChevronRight: 'chevronRight',
  Close: 'close',
  Cloud: 'cloud',
  Copy: 'copy',
  Favorite: 'favorite',
  Menu: 'menu',
  More: 'more',
  Photo: 'photo',
  Search: 'search',
  Send: 'send',
  Share: 'share',
  Check: 'check',
  Dealer: 'dealer',
  OpenInNew: 'openInNew',
  MoreVert: 'moreVert',
  ExpandMore: 'expandMore',
  ExpandLess: 'expandLess',
  InfoOutlinedIcon: 'InfoOutlinedIcon',
  DoneIcon: 'DoneIcon',
  Refresh: 'RefreshIcon',
  PrintIcon: 'PrintIcon',
  CalendarTodayIcon: 'CalendarTodayIcon',
  ArrowBackIcon: 'ArrowBackIcon',
  LocationOn: 'LocationOn',
  AddAPhotoIcon: 'AddAPhotoIcon',
};

const IconColor = {
  Base: 'base',
  Disabled: 'disabled',
  Secondary: 'secondary',
  Primary: 'primary',
};

const IconSize = {
  Md: 'md',
  Sm: 'sm',
  Xs: 'xs',
  Md2: 'md2',
  Xxl: '2xl',
};

const Icon = ({
  color,
  icon,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY,
  size,
  rotate = 0,
  isClickable = false,
  ...props
}) => {
  const renderIcon = () => {
    switch (icon) {
      case IconName.ChevronLeft:
        return <IconChevronLeft />;
      case IconName.ChevronRight:
        return <IconChevronRight />;
      case IconName.Dealer:
        return <IconDealer />;
      case IconName.Search:
        return <IconSearch />;
      case IconName.Send:
        return <IconSend />;
      case IconName.ArrowBack:
        return <IconArrowBackIosNew />;
      case IconName.Favorite:
        return <IconFavorite />;
      case IconName.Share:
        return <IconShare />;
      case IconName.More:
        return <IconMoreHoriz />;
      case IconName.Copy:
        return <IconContentCopy />;
      case IconName.Menu:
        return <IconMenu />;
      case IconName.Close:
        return <IconClose />;
      case IconName.Photo:
        return <IconPhoto />;
      case IconName.Cloud:
        return <IconCloudDownload />;
      case IconName.Check:
        return <IconCheck />;
      case IconName.OpenInNew:
        return <IconOpenInNew />;
      case IconName.MoreVert:
        return <MoreVertIcon />;
      case IconName.ExpandMore:
        return <IconExpandMore />;
      case IconName.ExpandLess:
        return <IconExpandLess />;
      case IconName.InfoOutlinedIcon:
        return <InfoOutlinedIcon />;
      case IconName.DoneIcon:
        return <DoneIcon />;
      case IconName.Refresh:
        return <RefreshIcon />;
      case IconName.PrintIcon:
        return <PrintIcon />;
      case IconName.CalendarTodayIcon:
        return <CalendarTodayIcon />;
      case IconName.ArrowBackIcon:
        return <ArrowBackIcon />;
      case IconName.LocationOn:
        return <LocationOnIcon />;
      case IconName.AddAPhotoIcon:
        return <AddAPhotoIcon />;
      default:
        return null;
    }
  };

  return (
    <IconContainer
      {...props}
      color={color}
      size={size}
      isCentered
      rotate={rotate}
      isClickable={isClickable}
      sx={{
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        marginX,
        marginY,
      }}
    >
      {renderIcon()}
    </IconContainer>
  );
};

export default Icon;

export const setIconColor = ({ color = IconColor.Base, theme }) => {
  const map = {
    [IconColor.Base]: theme.color.ACVTextLm.textSecondaryColor,
    [IconColor.Disabled]: theme.color.ACVTextLm.textDisabledColor,
    [IconColor.Primary]: theme.color.ACVTextLm.textPrimaryColor,
    [IconColor.Secondary]: theme.color.Drivably.secondaryColorDark,
  };

  return { color: map[color] || color };
};

const IconContainer = styled(Flex, {
  shouldForwardProp: (name) =>
    !['color', 'size', 'rotate', 'isClickable'].includes(name),
})`
  ${setIconColor}

  .SvgIcon-root, .MuiSvgIcon-root {
    ${({ size = IconSize.Md }) => {
      const map = {
        [IconSize.Xxl]: {
          fontSize: '54px',
          height: '54px',
          width: '54px',
        },
        [IconSize.Md]: {
          fontSize: '24px',
          height: '24px',
          width: '24px',
        },
        [IconSize.Sm]: {
          fontSize: '12px',
          height: '12px',
          width: '12px',
        },
        [IconSize.xs]: {
          fontSize: '20px',
          height: '20px',
          width: '20px',
        },
        [IconSize.Md2]: {
          fontSize: '16px',
          height: '16px',
          width: '16px',
        },
      };

      return map[size];
    }}
    ${({ rotate }) => rotate && `transform: rotate(${rotate}deg);`}
  }
  ${({ isClickable }) => isClickable && `cursor : pointer;`}
`;
