import { createContext, useContext } from 'react';

import { bindActions } from '@portal/lib';
import { tableActions } from '@portal/ui/ducks';

// Table context
export const TableContext = createContext({ api: null });
TableContext.displayName = 'TableContext';

export const useTableContext = () => useContext(TableContext);

// Table actions context
export const TableActionsContext = createContext(bindActions(tableActions));
TableActionsContext.displayName = 'TableActionsContext';

export const useTableActionContext = () => useContext(TableActionsContext);
