import {
  Box,
  ButtonBase,
  ButtonIcon,
  IconAddCameraV2,
  IconExpandMore,
  Image,
  Text,
} from '@drivably-apps/component-lib';

import {
  caseInsensitive,
  DateFormats,
  diffDate,
  exportFilterDate,
  exportFilterStatus,
  exportFilterValidValue,
  handleDate,
  VehicleStatus,
} from '@portal/lib';
import LeadListStatusWithDropdown from './LeadListStatusWithDropdown';
import LeadListSchedule from './LeadListSchedule';
import styled from '@emotion/styled';
import { disabledPhotoCaptureStoreIds } from './utils';

export const getLeadListColumns = ({
  hasSearchTerm,
  hideStoreCol,
  locale,
  onCustomCellClick,
  onExpandMoreClick,
  onInspectButtonClick,
  onThumbnailImageClick,
}) => {
  const columns = [
    {
      field: 'created_at',
      headerName: locale.leadTableCreatedAt,
      comparator: diffDate,
      // width: 150,
      filter: exportFilterDate,
      filterParams: { locale },
      cellRenderer: ({ data }) => {
        const { created_at: createdAt, lead_viewed } = data;
        const weight = lead_viewed ? 'normal' : 'bold';
        return (
          <Box
            direction="column"
            align="center"
            onClick={() => onCustomCellClick(data)}
          >
            <TextComponent size="xs" weight={weight}>
              {handleDate(createdAt, DateFormats.mmmm_d_yyyy)}
            </TextComponent>
            <TextComponent size="xs" weight={weight}>
              {handleDate(createdAt, DateFormats.h_mm_A)}
            </TextComponent>
          </Box>
        );
      },
    },
    {
      field: 'vehicle',
      headerName: locale.leadTableVehicle,
      maxWidth: 70,
      sortable: false,
      cellRenderer: ({ data }) => {
        const { vehicle: imageSrc, uuid, store__name, store__id } = data;

        return (
          <Box isFlex justify="center" align="center" width="full">
            {imageSrc ? (
              <ButtonBase onClick={() => onThumbnailImageClick(imageSrc)}>
                <Image
                  alt={imageSrc}
                  height="45px"
                  isCover
                  src={imageSrc}
                  width="45px"
                  radius="md"
                />
              </ButtonBase>
            ) : (
              <ButtonIcon
                isDisabled={disabledPhotoCaptureStoreIds.has(store__id)}
                backgroundColor="primary"
                onClick={() => onInspectButtonClick(uuid, store__name)}
              >
                <IconAddCameraV2 />
              </ButtonIcon>
            )}
          </Box>
        );
      },
    },
    {
      field: 'vehicle__info',
      headerName: '',
      sortable: false,
      flex: 1,
      minWidth: 250,
      cellRenderer: ({ data }) => {
        const { year, make, model, vin, mi, lead_viewed } = data;
        const weight = lead_viewed ? 'normal' : 'bold';
        return (
          <Box
            isFlex
            direction="column"
            width="300"
            onClick={() => onCustomCellClick(data)}
          >
            {RenderTextComponent({
              size: 'md',
              content: `${year || ''} ${make || ''} ${model || ''}`,
            })}
            {vin &&
              RenderTextComponent({
                size: 'sm',
                weight,
                content: `${locale.leadTableVIN}: ${vin}`,
              })}
            {mi &&
              RenderTextComponent({
                size: 'sm',
                weight,
                content: `${locale.vehicleDetailStockMileage}: ${mi} ${locale.vehicleLeadMiles}`,
              })}
          </Box>
        );
      },
    },
    {
      field: 'store__name',
      headerName: locale.leadTableStore,
      flex: 0,
      minWidth: 150,
      maxWidth: 250,
      hide: hideStoreCol,
      comparator: caseInsensitive,
      cellRenderer: ({ data }) => {
        const {
          store__name,
          store__address,
          store__city,
          store__state,
          lead_viewed,
        } = data;
        const weight = lead_viewed ? 'normal' : 'bold';
        return (
          <Box
            isFlex
            direction="column"
            onClick={() => onCustomCellClick(data)}
          >
            {RenderTextComponent({
              content: `${store__name || ''}`,
            })}
            {store__address &&
              RenderTextComponent({
                weight,
                content: `${store__address}`,
              })}
            {store__city &&
              store__state &&
              RenderTextComponent({
                weight,
                content: `${store__city || ''} ${store__state && ','} ${
                  store__state || ''
                }`,
              })}
          </Box>
        );
      },
    },
    {
      field: 'contact__name',
      headerName: locale.leadTableCustomerInfo,
      comparator: caseInsensitive,
      flex: 0.5,
      minWidth: 200,
      maxWidth: hideStoreCol ? 350 : 450,
      cellRenderer: ({ data }) => {
        const { contact__email, contact__name, contact__mobile, lead_viewed } =
          data;
        const weight = lead_viewed ? 'normal' : 'bold';
        return (
          <Box
            isFlex
            direction="column"
            onClick={() => onCustomCellClick(data)}
          >
            {RenderTextComponent({
              content: `${contact__name || ''}`,
            })}
            {RenderTextComponent({
              weight,
              content: `${contact__mobile || ''}`,
            })}
            {RenderTextComponent({
              weight,
              content: `${contact__email || ''}`,
            })}
          </Box>
        );
      },
    },
    {
      field: 'status__updated_at',
      sortable: false,
      headerName: locale.leadTableStatus,
      minWidth: 150,
      maxWidth: 250,
      cellRenderer: ({ data }) => {
        const { id, meeting, status } = data ?? {};
        if (!id || !status) {
          return null;
        }

        const meetingVehicleId = meeting?.vehicle_id;

        return (
          <Box isFlex flexGap="sm">
            {status.map((statusText) => (
              <LeadListStatusWithDropdown
                hasSearchTerm={hasSearchTerm}
                id={id}
                key={statusText}
                status={statusText}
                pastMeetingVehicleId={Boolean(meetingVehicleId)}
              />
            ))}
          </Box>
        );
      },
      filter: exportFilterStatus,
      filterParams: { locale },
    },
    {
      field: 'meeting',
      sortable: false,
      headerName: locale.leadTableScheduleDateTime,
      cellRenderer: ({ data }) => {
        const { id, meeting, status = [] } = data;
        const scheduleDate = meeting?.date;
        const meetingVehicleId = meeting?.vehicle_id;
        const isStatusAppointment = status.some((val) =>
          [VehicleStatus.BookedVisit].includes(val)
        );

        return (
          <LeadListSchedule
            hasSearchTerm={hasSearchTerm}
            id={id}
            isStatusAppointment={isStatusAppointment}
            scheduleDate={scheduleDate}
            pastMeetingVehicleId={meetingVehicleId}
          />
        );
      },
    },
    {
      field: 'trim',
      headerName: locale.leadTableTrim,
      minWidth: 150,
      comparator: caseInsensitive,
    },
    {
      field: 'source_platform',
      maxWidth: 80,
      headerName: locale.leadTableSource,
      comparator: caseInsensitive,
    },
    {
      field: 'actions',
      minWidth: 100,
      cellRenderer: ({ data }) => {
        const { id } = data;

        return (
          <Box isFlex>
            <ButtonIcon onClick={() => onExpandMoreClick?.(id)}>
              <IconExpandMore />
            </ButtonIcon>
          </Box>
        );
      },
    },
  ];

  return columns;
};

export const getAdditionalLeadListColumns = ({ locale }) => {
  const offerValue = {
    field: 'offer',
    headerName: locale.vehicleDetailTabOffer,
    exportFilter: exportFilterValidValue,
  };

  let originalColumns = getLeadListColumns({ locale });
  originalColumns.splice(2, 0, offerValue);

  return originalColumns;
};

const TextComponent = styled(Text)`
  color: ${({ theme, props }) =>
    props?.textColor || theme.color.GlobalText.tertiaryColor} !important;
`;

const RenderTextComponent = ({
  size = 'xs',
  weight = 'bold',
  color = 'gray-text',
  content,
}) => {
  return (
    <Text size={size} weight={weight} color={color} marginBottom="2xs">
      {content}
    </Text>
  );
};
