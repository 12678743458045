const currencies = {
  default: 'USD',
  'en-us': 'USD',
  'en-ca': 'CAD',
};

export const formatCurrency = (value = '', locale = 'en-US') => {
  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencies[locale.toLowerCase()] || currencies.default,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  return currencyFormatter.format(value);
};
