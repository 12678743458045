import { useLocalStorage } from './useLocalStorage';

/** Manage auth related data */
export const useToken = () => {
  const [token, setToken] = useLocalStorage('token', '');
  const [userId, setUserId] = useLocalStorage('userId', '');

  const clearToken = () => {
    setToken('');
    setUserId('');
  };

  const saveToken = ({ token, userId }) => {
    setToken(token);
    setUserId(userId);
  };

  return {
    clearToken,
    saveToken,
    token,
    userId,
  };
};
