import { gql } from '@apollo/client';

export const SendEmail = gql`
  query SendEmail(
    $data: String!
    $from: String!
    $to: String!
    $subject: String!
    $to_alias: String
    $from_alias: String
  ) {
    sendEmail(
      data: $data
      from: $from
      to: $to
      subject: $subject
      from_alias: $from_alias
      to_alias: $to_alias
    ) {
      success
    }
  }
`;
