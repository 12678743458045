export const UUIDRegexStr =
  '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

/**
 * Make underscore cased strings user friendly (e.g. hello_world -> Hello World)
 *
 * @param {string} str - Underscore cased string
 * @returns {string} Title cased string
 */
export const underscoreToTitleCase = (str = '') => {
  const words = str.split('_');

  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const splitCapitalLetters = (str = '') => {
  return str
    .split(/(?=[A-Z])/)
    .join(' ')
    .trim();
};

export const nameValidation = (name = '') => {
  return name?.trim() && name.length <= 50;
};

export function truncateWithEllipsis(text, maxLength) {
  if (text.length > maxLength) {
    text = text.substring(0, maxLength - 3) + '...';
  }
  return text;
}
