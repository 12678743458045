import { US } from '@portal/static/drilldown';

/** Generate list of all makes, models and styles from drilldown static data */
const getDrilldown = (drilldown) => {
  const yearList = new Map();
  const makeList = new Map();
  const modelList = new Map();
  const styleList = new Map();

  // Year
  Object.entries(drilldown).forEach(([yearKey, makeData]) => {
    // Year keys have prefix `y` since name exports cannot be all numbers
    const year = yearKey.slice(1);
    yearList.set(year, {});

    // Make
    Object.entries(makeData).forEach(([make, modelData]) => {
      let makeMeta = makeList.get(make);
      if (!makeMeta) {
        makeMeta = {
          year: new Set(),
        };
      }

      makeMeta.year.add(year);

      makeList.set(make, makeMeta);

      // Model
      Object.entries(modelData).forEach(([model, styleData]) => {
        let modelMeta = modelList.get(model);
        if (!modelMeta) {
          modelMeta = {
            make: new Set(),
            year: new Set(),
          };
        }

        modelMeta.year.add(year);
        modelMeta.make.add(make);

        modelList.set(model, modelMeta);

        // Style
        Object.entries(styleData).forEach(([style]) => {
          let styleMeta = styleList.get(style);
          if (!styleMeta) {
            styleMeta = {
              make: new Set(),
              model: new Set(),
              year: new Set(),
            };
          }

          styleMeta.year.add(year);
          styleMeta.make.add(make);
          styleMeta.model.add(model);

          styleList.set(style, styleMeta);
        });
      });
    });
  });

  return {
    makeList: [...makeList].sort(([a], [b]) => stringSortComparer(a, b)),
    modelList: [...modelList].sort(([a], [b]) => stringSortComparer(a, b)),
    styleList: [...styleList].sort(([a], [b]) => stringSortComparer(a, b)),
    yearList: [...yearList].sort(([a], [b]) => stringSortComparer(a, b, true)),
  };
};

const stringSortComparer = (a = '', b = '', isDescending) => {
  var nameA = a.toUpperCase();
  var nameB = b.toUpperCase();
  if (nameA < nameB) {
    return isDescending ? 1 : -1;
  }

  if (nameA > nameB) {
    return isDescending ? -1 : 1;
  }

  return 0;
};

export const DrilldownUs = getDrilldown(US);
