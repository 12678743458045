import { FormControlLabel } from '@mui/material';
import CheckboxMui from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { styled } from './lib';

const Checkbox = ({
  hasPadding = true,
  isChecked = false,
  isCircle = false,
  label,
  onChange,
  ...props
}) => {
  if (label) {
    return (
      <FormControlLabel
        label={label}
        sx={{ pl: 1.5 }}
        control={
          <CheckboxMuiStyled
            {...props}
            disableRipple
            edge="start"
            hasPadding={hasPadding}
            isChecked={isChecked}
            onChange={onChange}
            tabIndex={-1}
          />
        }
      />
    );
  }
  return (
    <CheckboxMuiStyled
      checked={isChecked}
      hasPadding={hasPadding}
      onChange={onChange}
      {...(isCircle && {
        icon: <RadioButtonUncheckedIcon />,
        checkedIcon: <CheckCircleIcon />,
      })}
      tabIndex={-1}
      {...props}
    />
  );
};

export default Checkbox;

const CheckboxMuiStyled = styled(CheckboxMui, {
  shouldForwardProp: (name) => !['hasPadding'].includes(name),
})`
  padding: ${({ hasPadding }) => (hasPadding ? '7.5px' : '0')};

  color: ${({ theme }) => theme.color.ACVTextLm.textDisabledColor};

  &.Mui-checked {
    color: ${({ theme }) => theme.color.Drivably.primaryColor};
  }

  &.Mui-checked__error {
    color: ${({ theme }) => theme.color.backgrounds.gray};
  }

  .SvgIcon-root {
    font-size: 29px;
    width: 29px;
    height: 29px;
  }
`;
