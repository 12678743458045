import { useRef, useEffect, useState } from 'react';
import {
  Box,
  DateTimePicker,
  IconExpandMore,
  IconSpinner,
  ButtonBase,
  Text,
} from '@drivably-apps/component-lib';

import { dayjs, DefaultStatusFlags, VehicleStatus } from '@portal/lib';
import { useMutation, useLocale } from '@portal/hooks';
import {
  VehicleCreateMeetingGql,
  VehicleLeadsCountWithoutSearchGql,
  VehicleLeadsCountWithSearchGql,
  VehicleLeadSearchGql,
  VehicleLeadSearchNoTermGql,
  VehicleUpdateMeetingGql,
  VehicleUpdateStatusGql,
} from '@portal/query/graphql';

const DateTimeFormat = 'M/D, h:mma';

const LeadListSchedule = ({
  hasSearchTerm,
  id,
  isStatusAppointment,
  scheduleDate,
  pastMeetingVehicleId,
}) => {
  const { locale } = useLocale();
  const [isOpen, setIsOpen] = useState(false);
  const boxRef = useRef(null);

  const hasPastMeetingId = Boolean(pastMeetingVehicleId);

  const getDayJSObject = (date) => (Boolean(date) ? dayjs(date) : null);

  const [dateValue, setDateValue] = useState(() =>
    getDayJSObject(scheduleDate)
  );

  const meetingMutationOptions = {
    isSuccessAlertMuted: true,
    refetchQueries: hasSearchTerm
      ? [VehicleLeadSearchGql, VehicleLeadsCountWithSearchGql]
      : [VehicleLeadSearchNoTermGql, VehicleLeadsCountWithoutSearchGql],
  };

  const [createMeeting, createMeetingRes] = useMutation(
    VehicleCreateMeetingGql,
    meetingMutationOptions
  );

  const [updateMeeting, updateMeetingRes] = useMutation(
    VehicleUpdateMeetingGql,
    meetingMutationOptions
  );

  const [updateStatus, updateStatusRes] = useMutation(VehicleUpdateStatusGql, {
    isSuccessAlertMuted: true,
  });

  useEffect(() => {
    const controlledDate = getDayJSObject(scheduleDate);
    setDateValue(controlledDate);
  }, [scheduleDate]);

  const handleConfirm = (newValue) => {
    // Clear seconds and milliseconds, calendar only supports up to minutes
    const newDate = newValue.second(0).millisecond(0).utc().format();

    setDateValue(newValue);
    setIsOpen(false);

    // Automatically set status to booked visit
    if (!isStatusAppointment) {
      updateStatus({
        variables: {
          vehicle_id: id,
          _set: {
            ...DefaultStatusFlags,
            [VehicleStatus.BookedVisit]: true,
          },
        },
      });
    }

    const payload = {
      variables: {
        vehicle_id: id,
        date: newDate,
      },
    };

    if (hasPastMeetingId) {
      updateMeeting(payload);
    } else {
      createMeeting(payload);
    }
  };

  const handleCalendarClose = () => {
    setIsOpen(false);

    const originalDate = getDayJSObject(scheduleDate);
    setDateValue(originalDate);
  };

  const isLoading =
    updateMeetingRes.isLoading ||
    updateStatusRes.isLoading ||
    createMeetingRes.isLoading;

  const renderedIcon = isLoading ? (
    <IconSpinner size="sm" />
  ) : (
    <IconExpandMore />
  );

  const renderedText =
    (hasPastMeetingId && dateValue?.format(DateTimeFormat)) ||
    locale.leadTableScheduleNow;

  return (
    <>
      <ButtonBase borderRadius="md" onClick={() => setIsOpen(true)}>
        <Box
          forwardedRef={boxRef}
          isFlex
          align="center"
          flexGap="md"
          padding="xs"
        >
          <Text size="sm" isUnderlined>
            {renderedText}
          </Text>
          {renderedIcon}
        </Box>
      </ButtonBase>
      <DateTimePicker
        isOpen={isOpen}
        isPastDisabled
        onClose={handleCalendarClose}
        onConfirm={handleConfirm}
        target={boxRef.current}
        value={dateValue}
      />
    </>
  );
};

export default LeadListSchedule;
