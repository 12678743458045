import { Form as FormMui } from 'formik';

import { styled } from './lib';

const Form = (props) => <FormMuiStyled {...props} />;

export default Form;

const FormMuiStyled = styled(FormMui)`
  ${({ width }) => width && { width }}
  ${({ height }) => height && { height }}
`;
