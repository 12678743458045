import { useMutation as useMutationAC } from '@apollo/client';

import { useAlert } from './useAlert';

/**
 * Integrate error handling and alert notification on apollo client useMutation
 * hook
 */
export const useMutation = (
  query,
  {
    errorText,
    isSuccessAlertMuted = false,
    onCompleted,
    onError,
    successText,
    ...props
  } = {}
) => {
  const { setSuccessAlert, setErrorAlert } = useAlert();

  const handleComplete = (data) => {
    if (!isSuccessAlertMuted) {
      setSuccessAlert(successText);
    }

    onCompleted?.(data);
  };

  const handleError = (error) => {
    setErrorAlert(errorText);
    onError?.(error);
  };

  const [func, { loading: isLoading, ...data }] = useMutationAC(query, {
    ...props,
    onCompleted: handleComplete,
    onError: handleError,
  });

  const funcWithError = async (params) => {
    try {
      const res = await func(params);
      return res;
    } catch (e) {
      handleError(e);
    }
  };

  return [funcWithError, { isLoading, ...data }];
};
