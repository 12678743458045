import { Box, Loader, Separator, Text } from '@drivably-apps/component-lib';

import { ModalAction } from './ModalAction';
import { ModalBoxBase } from './ModalBoxBase';
import { ModalClose } from './ModalClose';
import { ModalContent } from './ModalContent';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';

/** ModalBox renders a styled box with complete overlay sections. */
export const ModalBox = ({
  backId,
  backText,
  children,
  error,
  footerText,
  gap,
  hasSeparator = false,
  headerText,
  isActionLoading,
  isContentLoading,
  isDisabled,
  nextId,
  nextText,
  onBackClick,
  onClose,
  onNextClick,
  title,
  isNextButtonRounded = false,
  nextButtonTooltipText = '',
}) => {
  // Prevent action when intended, content is loading or an error happened
  const isActionDisabled = isDisabled || isContentLoading || Boolean(error);

  return (
    <ModalBoxBase gap={gap}>
      <Box align="center">
        {title && <Text headingType="h2">{title}</Text>}
        {onClose && <ModalClose onClose={onClose} />}
      </Box>
      {headerText && <ModalHeader text={headerText} />}
      {hasSeparator && <Separator />}
      <ModalContent>
        {children}
        {/* Render an overlay loader to modal content */}
        {isContentLoading && (
          // TODO support absolute position
          <Box
            height="full"
            width="full"
            color="light"
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              zIndex: 1,
            }}
          >
            <Loader height="100%" width="100%" type="rounded" />
          </Box>
        )}
        {/* Render an overlay error to modal content */}
        {error && (
          // TODO support absolute position
          <Box
            height="full"
            width="full"
            color="light"
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              zIndex: 1,
            }}
            justify="center"
            align="center"
          >
            <Text color="error" headingType="h4">
              {error}
            </Text>
          </Box>
        )}
      </ModalContent>
      <ModalAction
        backId={backId}
        backText={backText}
        isDisabled={isActionDisabled}
        isLoading={isActionLoading}
        nextId={nextId}
        nextText={nextText}
        onBackClick={onBackClick || onClose}
        onNextClick={onNextClick}
        isNextButtonRounded={isNextButtonRounded}
        nextButtonTooltipText={nextButtonTooltipText}
      />
      {footerText && <ModalFooter text={footerText} />}
    </ModalBoxBase>
  );
};
