export const caseInsensitive = (valueA, valueB) => {
  if (typeof valueA === 'string') {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  }

  return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
};

/** Capitalize first letter of a word */
export const upperFirst = (string = '') => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};
