import { isValidElement } from 'react';

import MuiAccordion from '@mui/material/Accordion';
import {
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled } from './lib';

const Accordion = ({
  summary,
  details,
  expandIcon = <ExpandMoreIcon />,
  detailStyle = {},
  summaryStyle = {},
  ...props
}) => (
  <AccordionContainer disableGutters {...props}>
    {isValidElement(summary) && (
      <AccordionSummary
        expandIcon={expandIcon}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        style={summaryStyle}
      >
        {summary}
      </AccordionSummary>
    )}
    {isValidElement(details) && (
      <AccordionDetails style={detailStyle}>{details}</AccordionDetails>
    )}
  </AccordionContainer>
);

export default Accordion;

const AccordionContainer = styled(MuiAccordion)`
  justify-content: space-between;

  width: 100%;
  height: fit-content;

  border-radius: 3px;
  box-shadow: none;
  border: 1px solid #eaeaea;
`;

const AccordionSummary = styled(MuiAccordionSummary)`
  background: ${({ theme }) => theme.color.raw.white};

  border-bottom: 1px solid #eaeaea;
  padding: 15px 20px;

  & .AccordionSummary-content {
    margin: 0px;
  }
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  background: ${({ theme }) => theme.color.raw.gray2};
  padding: 15px 20px;
`;
