import { appointmentDays } from './constants';
import { addDaysToDate, getISODate } from './date';

export const getAppointmentDate = (date, DaysToAdd) => {
  const newDate = addDaysToDate(date, DaysToAdd);

  // Meeting date stored in DB is in ISO format, so we convert the date to ISO format while sending the query.
  return getISODate(newDate);
};

//Fetch appointments between selected and next date (meeting_date is timestamp).
export const generateAppointmentDateQuery = (date = new Date()) => ({
  meeting: {
    date: {
      _gte: date,
      _lt: getAppointmentDate(date, appointmentDays.DaysToAdd),
    },
  },
});
