import Snackbar from '@mui/material/Snackbar';
import AlertMui from '@mui/material/Alert';

import { styled } from './lib';

const Alert = ({ type, message, onClose }) => (
  <Snackbar
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    onClose={onClose}
    open={Boolean(message)}
  >
    <AlertStyled onClose={onClose} severity={type} variant="filled">
      {message}
    </AlertStyled>
  </Snackbar>
);

export default Alert;

const AlertStyled = styled(AlertMui)`
  width: 100%;
`;
