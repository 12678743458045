import { styled } from '@portal/ui';
import { Box, Button, Text } from '@drivably-apps/component-lib';

const AppError = ({ statusCode, isFullPage = false }) => (
  <Container
    align="center"
    direction="column"
    flexGap="sm"
    height="full"
    isFlex
    isFullPage={isFullPage}
    justify="center"
    width="full"
  >
    <Text headingType="h1">Oops, there is an error!</Text>
    <Text headingType="h2" marginBottom="xl">
      {statusCode
        ? `An error ${statusCode} occurred on server`
        : 'An error occurred on client (see console for more details)'}
    </Text>
    <Button onClick={() => location.reload()}>Try again</Button>
  </Container>
);

export default AppError;

const Container = styled(Box)`
  ${({ isFullPage }) =>
    isFullPage &&
    `
        width: 100vw;
        height: 100vh;
    `}
`;
