import TooltipMui, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from './lib';

const Tooltip = ({ children, isActive = true, bgColor, ...props }) => {
  if (!isActive) {
    return children;
  }

  return (
    <TooltipMuiStyled {...props} bgColor={bgColor}>
      <span>{children}</span>
    </TooltipMuiStyled>
  );
};

export default Tooltip;

// Styled TooltipMui with dynamic background color based on props
const TooltipMuiStyled = styled(({ className, ...props }) => (
  <TooltipMui {...props} arrow classes={{ popper: className }} />
))(({ bgColor }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: bgColor,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: bgColor,
  },
}));
