import { gql } from '@apollo/client';

export const DistanceGetCoordinatesGql = gql`
  query DistanceGetCoordinates($dealerZip: String!, $consumerZip: String!) {
    dealerCoordinates: coordinates_for_zip(zip: $dealerZip) {
      lat
      long
    }
    consumerCoordinates: coordinates_for_zip(zip: $consumerZip) {
      lat
      long
    }
  }
`;
