/**
 * Bind dispatch to actions for direct action calls without wrapping with
 * dispatch
 */
export const bindActions = (actions = {}, dispatch = () => {}) => {
  return Object.entries(actions).reduce((obj, [funcName, funcBody]) => {
    obj[funcName] = (...args) => dispatch(funcBody(...args));
    return obj;
  }, {});
};
