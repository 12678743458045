import {
  exportFilterDate,
  exportFilterValidValue,
  exportFilterInspection,
  diffDate,
  sortFloating,
  caseInsensitive,
  sortPrice,
} from '@portal/lib';

export const getAppraiserListColumns = ({
  locale,
  renderButton = () => {},
  renderStatus = () => {},
}) => [
  {
    field: 'created_at',
    headerName: locale.appraiserTableCreatedAt,
    exportFilter: exportFilterDate,
    comparator: diffDate,
  },
  {
    field: 'offer',
    headerName: locale.appraiserTableOffer,
    comparator: sortPrice,
  },
  {
    field: 'vin',
    headerName: locale.appraiserTableVIN,
    style: { textTransform: 'uppercase' },
  },
  { field: 'year', headerName: locale.appraiserTableYear },
  {
    field: 'make',
    headerName: locale.appraiserTableMake,
    comparator: caseInsensitive,
  },
  {
    field: 'model',
    headerName: locale.appraiserTableModel,
    comparator: caseInsensitive,
  },
  {
    field: 'trim',
    headerName: locale.appraiserTableTrim,
    comparator: caseInsensitive,
  },
  {
    field: 'mi',
    headerName: locale.appraiserTableOdometer,
    comparator: sortFloating,
  },
  {
    field: 'store__name',
    headerName: locale.appraiserTableStore,
    comparator: caseInsensitive,
  },
  {
    field: 'appraiser',
    headerName: locale.appraiserTableAppraiser,
    exportFilter: exportFilterValidValue,
  },
  {
    field: 'appraisal',
    headerName: locale.vehicleDetailAppraiserCard,
    exportFilter: exportFilterValidValue,
    cellRenderer: renderStatus,
  },
  {
    field: 'inspection_request',
    headerName: locale.appraiserTableInspection,
    flex: 1,
    minWidth: 220,
    cellRenderer: renderButton,
    exportFilter: exportFilterInspection,
  },
];
