import { ACVTheme } from './acv';
import { DrivablyTheme } from './drv';

export const SupportedTheme = {
  Acv: 'acv',
  Drivably: 'drv',
};

const themes = {
  ACV: ACVTheme,
  DRV: DrivablyTheme,
  default: DrivablyTheme,
};

export const theme =
  themes[process.env.NEXT_PUBLIC_APP_THEME?.toUpperCase()] || themes.default;
