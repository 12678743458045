import Typography from '@mui/material/Typography';

import { styled } from './lib';
import { Text as TextCL } from '@drivably-apps/component-lib';

export const TextRootCls = '.Typography-root';

const TextColor = {
  Base: 'base',
  BaseContrast: 'baseContrast',
  Brand: 'brand',
  BrandContrast: 'brandContrast',
  Error: 'error',
  Info: 'info',
  Primary: 'primary',
  Secondary: 'secondary',
  Success: 'success',
  Warning: 'warning',
};

const TextType = {
  Body: 'body',
  Caption: 'caption',
  H1: 'h1',
  H5: 'h5',
  H6: 'h6',
  HeadingSubtitle: 'headingSubtitle',
  Subtitle1: 'subtitle1',
  Subtitle2: 'subtitle2',
  Subtitle3: 'subtitle3',
  SubtitleBase: 'subtitleBase',
  Overline: 'overline',
};

const Text = ({
  isCapitalized = false,
  isBreakWord = false,
  isOverflowEllipsis = false,
  isUpperCase = false,
  isVisible = true,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY,
  textAlign,
  ...props
}) => (
  <TypographyStyled
    {...props}
    isCapitalized={isCapitalized}
    isbreakword={isBreakWord ? 1 : 0}
    isOverflowEllipsis={isOverflowEllipsis}
    isUpperCase={isUpperCase}
    isVisible={isVisible}
    sx={{
      margin,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      marginX,
      marginY,
      textAlign,
    }}
  />
);

export default Text;

export const setTextColor = ({ color = TextColor.Base, theme }) => {
  const map = {
    [TextColor.Base]: theme.color.text.text3,
    [TextColor.BaseContrast]: theme.color.text.contrast,
    [TextColor.Brand]: theme.color.Drivably.primaryColor,
    [TextColor.BrandContrast]: theme.color.raw.white,
    [TextColor.Error]: theme.color.ACVErrorAlertShades.uiErrorColorBase,
    [TextColor.Info]:
      theme.color.ACVInformationalShades.uiInformationalColorBase,
    [TextColor.Primary]: theme.color.ACVTextLm.textPrimaryColor,
    [TextColor.Secondary]: theme.color.ACVTextLm.textSecondaryColor,
    [TextColor.Success]: theme.color.ACVSuccessShadesLm.uiSuccessColorBase,
    [TextColor.Warning]: theme.color.ACVPrimaryShadesLm.primaryColorBase,
  };

  return { color: map[color] };
};

export const setTextTypography = ({ type = TextType.Body, theme }) => {
  const map = {
    [TextType.Body]: theme.typography.GlobalText.bodyBaseRegular,
    [TextType.Caption]: theme.typography.caption,
    [TextType.H1]: theme.typography.raw.h1,
    [TextType.H5]: theme.typography.GlobalHeading.h5Bold,
    [TextType.H6]: theme.typography.GlobalHeading.h6Medium,
    [TextType.HeadingSubtitle]:
      theme.typography.GlobalHeading.subtitleSmRegular,
    [TextType.Subtitle1]: theme.typography.subtitle1,
    [TextType.Subtitle2]: theme.typography.subtitle2,
    [TextType.Subtitle3]: theme.typography.subtitle3,
    [TextType.SubtitleBase]: theme.typography.GlobalHeading.subtitleBaseBold,
    [TextType.Overline]: theme.typography.GlobalText.overline,
  };

  return map[type];
};

const TypographyStyled = styled(Typography, {
  shouldForwardProp: (name) =>
    ![
      'align',
      'isOverflowEllipsis',
      'isUpperCase',
      'isCapitalized',
      'isVisible',
    ].includes(name),
})`
  ${setTextColor}
  ${setTextTypography}
  ${({ align }) => align && `text-align: ${align};`}
  ${({ isUpperCase }) => isUpperCase && `text-transform: uppercase;`}
  ${({ isBreakWord }) => isBreakWord && `word-wrap: break-word;`}
  ${({ isCapitalized }) => isCapitalized && `text-transform: capitalize;`}
  ${({ isVisible }) => !isVisible && `visibility: hidden;`}
  ${({ isOverflowEllipsis }) =>
    isOverflowEllipsis &&
    `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`;

export const HeaderSectionTitle = styled(TextCL)`
  color: ${({ theme }) => theme.color.ACVTextLm.textSecondaryColor} !important;
  letter-spacing: 1.5px !important;
  font-weight: 500 !important;
`;
