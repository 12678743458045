const TableActionType = {
  SET_API: 'set_api',
};

export const tableActions = {
  setApi: (api) => ({
    type: TableActionType.SET_API,
    payload: { api },
  }),
};

export const tableReducer = (state, action) => {
  switch (action.type) {
    case TableActionType.SET_API:
      return { ...state, api: action.payload.api };
    default:
      console.error(`Unknown action ${action.type} dispatched.`);
      return state;
  }
};
