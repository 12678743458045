import { isArray } from './array';

const earthRadius = 3958.8; // earth radius in miles
const sin = Math.sin;
const PI = Math.PI;
const asin = Math.asin;
const cos = Math.cos;
const sqrt = Math.sqrt;
const squared = Math.pow;

/* Convert coordinates to Radians */
const toRadians = (x) => {
  return (x * PI) / 180.0;
};

const haversineDistance = (x) => {
  return squared(sin(x / 2), 2);
};

/**
 * Haversine algorithm is a method to calculate the distance between two points
 * Haversine(theta) = haversine(consumerLat - dealerLat) + cos(dealerLat) *
 * cos(consumerLat) * haversine(consumerLon - dealerLon) Accepts an array of
 * coordinates or an object Return the distance in miles if the coordinates are
 * correct If not return NaN
 */
export const calculateDistance = (dealerCoordinates, consumerCoordinates) => {
  const isDealerArray = isArray(dealerCoordinates);
  const isConsumerArray = isArray(consumerCoordinates);

  const dealerLat = toRadians(
    isDealerArray
      ? dealerCoordinates[1]
      : dealerCoordinates?.latitude ?? dealerCoordinates?.lat
  );
  const consumerLat = toRadians(
    isConsumerArray
      ? consumerCoordinates[1]
      : consumerCoordinates?.latitude ?? consumerCoordinates?.lat
  );
  const dealerLng = toRadians(
    isDealerArray
      ? dealerCoordinates[0]
      : dealerCoordinates?.longitude ??
          dealerCoordinates?.lng ??
          dealerCoordinates?.long
  );
  const consumerLng = toRadians(
    isConsumerArray
      ? consumerCoordinates[0]
      : consumerCoordinates?.longitude ??
          consumerCoordinates?.lng ??
          consumerCoordinates?.long
  );

  const haversineTotal =
    haversineDistance(consumerLat - dealerLat) +
    cos(dealerLat) *
      cos(consumerLat) *
      haversineDistance(consumerLng - dealerLng);

  return 2 * earthRadius * asin(sqrt(haversineTotal));
};
