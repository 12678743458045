import { useUserContext } from '@portal/app';
import {
  Flex,
  Layout,
  LogoACV,
  styled,
  SupportedTheme,
  Text,
} from '@portal/ui';
import { AcvAuctionsUrl, AppTheme } from '@portal/lib';
import { useLocale } from '@portal/hooks';
import AppHead from './AppHead';
import { useDetectDevice } from '@drivably-apps/component-lib';

const AppLayout = ({
  activePath,
  children,
  hasNavBar,
  isAuthenticated = false,
  isInitializing = false,
  isRedirectingToLogin = false,
  navigationItems = [],
  onLogoClick,
  onLogoutClick,
  onNavigationItemClick,
}) => {
  const { locale } = useLocale();
  const user = useUserContext();
  const { isMobile } = useDetectDevice();

  let content = children;

  if (isInitializing || isRedirectingToLogin) {
    content = null;
  } else if (isAuthenticated) {
    let acvMenuItems = [
      { text: locale.appAcvMarketplace, link: `${AcvAuctionsUrl}/search` },
      { text: locale.appAcvMyACV, link: `${AcvAuctionsUrl}/myacv` },
      {
        text: locale.appAcvMarketReport,
        link: `${AcvAuctionsUrl}/market-report`,
      },
      {
        text: locale.appAcvACVCapital,
        link: `${AcvAuctionsUrl}/capital`,
      },
      { text: locale.appAcvClearCar, link: '/', forceIsActive: true },
    ];

    if (isMobile) {
      acvMenuItems = acvMenuItems.sort((a, b) => {
        // Items with `forceIsActive: true` should come first
        const aActive = a.forceIsActive ? 1 : 0;
        const bActive = b.forceIsActive ? 1 : 0;

        return bActive - aActive;
      });
    }

    const acvProps = AppTheme === SupportedTheme.Acv && {
      menuItems: acvMenuItems,
      cobrand: (
        <Flex
          marginLeft="6px"
          marginTop="6px"
          alignItems="center"
          alignSelf="flex-start"
        >
          <ACVLogoTextStyled>{locale.appAcvCobrandFrom}</ACVLogoTextStyled>
          <LogoACV
            path={AcvAuctionsUrl}
            width={isMobile ? 30 : 45}
            height={20}
          />
        </Flex>
      ),
    };

    const { currentStore } = user?.meta ?? {};

    content = (
      <Layout
        {...acvProps}
        activePath={activePath}
        email={user.email}
        hasNavBar={hasNavBar}
        navigationItems={navigationItems}
        onLogoClick={onLogoClick}
        onLogoutClick={onLogoutClick}
        onNavigationItemClick={onNavigationItemClick}
        storeId={currentStore.id}
        storeName={currentStore.name}
        userName={user.name}
      >
        {children}
      </Layout>
    );
  }

  return (
    <>
      <AppHead />
      {content}
    </>
  );
};

export default AppLayout;

const ACVLogoTextStyled = styled(Text)`
  ${({ theme }) => theme.typography.raw.menuText};
  color: ${({ theme }) => theme.color.GlobalText.tertiaryColor};
`;
