import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import {
  IconClearCar,
  Link,
  useDetectDevice,
} from '@drivably-apps/component-lib';

import { usePopover } from './hooks';
import { styled } from './lib';
import ButtonIcon from './ButtonIcon';
import Box from './Box';
import Flex from './Flex';
import Menu from './Menu';
import Text, { setTextColor, TextRootCls } from './Text';
import A from './A';
import LayoutUserInfo from './LayoutUserInfo';
import NotificationPopover from './NotificationPopover';
import { useNotificationContext, useUserContext } from '@portal/app';
import { ApolloProvider } from '@apollo/client';

const LayoutNavBar = ({
  cobrand,
  email,
  height,
  items = [],
  onLogoClick,
  onLogoutClick,
  storeId,
  storeName,
  userName,
}) => {
  const [isOpen, toggleIsOpen, buttonRef] = usePopover();
  const user = useUserContext();
  const { isMobile } = useDetectDevice();

  const { notifications, unReadCount } = useNotificationContext();
  const notificationProps = {
    notificationCount: unReadCount,
    notifications,
  };

  const spacing = !isMobile ? { spacing: '23px' } : {};
  const iconStyle = isMobile
    ? { width: '80px', height: '25px' }
    : { width: '118px', height: '35px' };
  return (
    <>
      <AppBarStyled height={height}>
        <ToolbarStyled>
          <Link onClick={onLogoClick} style={{ cursor: 'pointer' }}>
            <IconClearCar {...iconStyle} />
          </Link>
          {cobrand}
          {items.length >= 1 && (
            <LayoutNavBarItemContainer
              isOverflowAuto
              marginRight="20px"
              minWidth={isMobile ? '15px' : '150px'}
              height="100%"
              marginLeft={isMobile ? '5px' : '30px'}
            >
              <Flex alignItems="center" height="100%" spacing="2rem">
                {items.map(({ text, link, forceIsActive }) => (
                  <LayoutNavBarItem
                    height="100%"
                    isActive={forceIsActive}
                    isCentered
                    key={text}
                  >
                    <A href={link}>
                      <Text
                        align="center"
                        color={forceIsActive ? 'warning' : 'base'}
                      >
                        {text}
                      </Text>
                    </A>
                  </LayoutNavBarItem>
                ))}
              </Flex>
            </LayoutNavBarItemContainer>
          )}
          <Flex {...spacing} marginLeft="auto">
            <ApolloProvider client={user?.meta?.clientInstance}>
              <NotificationPopover {...notificationProps} />
            </ApolloProvider>
            {userName && (
              <LayoutUserInfo
                email={email}
                name={userName}
                storeId={storeId}
                storeName={storeName}
              />
            )}
            <ButtonIcon icon="menu" ref={buttonRef} onClick={toggleIsOpen} />
          </Flex>
        </ToolbarStyled>
      </AppBarStyled>
      <Menu
        anchorRef={buttonRef}
        isOpen={isOpen}
        onClose={toggleIsOpen}
        options={[
          {
            ...(isMobile && { text: storeName }),
          },
          { text: 'Logout', onClick: onLogoutClick },
        ]}
      />
    </>
  );
};

export default LayoutNavBar;

const AppBarStyled = styled(AppBar)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  background: ${({ theme }) => theme.color.backgrounds.white};

  box-shadow: none;
  padding: 0;

  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  padding-right: 0px !important;
  overflow: auto !important;
  height: ${({ height }) => height};
`;

const ToolbarStyled = styled(Toolbar)`
  background: ${({ theme }) => theme.color.backgrounds.white};
  border-bottom: 1px solid ${({ theme }) => theme.color.raw.gray4};
  height: 100%;

  @media (min-width: 600px) {
    min-height: unset;
  }
`;

const LayoutNavBarItem = styled(Flex, {
  shouldForwardProp: (name) => !['isActive'].includes(name),
})`
  transition:
    border-bottom 0.2s,
    padding-left 0.2s,
    color 0.2s;
  border-bottom: 2px solid transparent;

  && {
    margin-bottom: 0;
  }

  &:hover {
    border-bottom: 2px solid
      ${({ theme }) => theme.color.ACVPrimaryShadesLm.primaryColorBase};

    ${TextRootCls} {
      ${({ theme, isActive }) =>
        isActive
          ? setTextColor({ color: 'warning', theme })
          : setTextColor({ color: 'baseContrast', theme })}
    }
  }

  ${({ isActive, theme }) =>
    isActive
      ? `
          border-bottom: 2px solid ${theme.color.ACVPrimaryShadesLm.primaryColorBase};
          cursor: default;
          color: red;
        `
      : `cursor: pointer;`}
`;

const LayoutNavBarItemContainer = styled(Box)`
  flex: 1 1 auto;
`;
