import Head from 'next/head';

const AppHead = () => (
  <Head>
    <title>ClearCar Portal</title>
    <meta name="description" content="This is the ClearCar portal" />
    <link rel="icon" href="/favicon.ico" />
  </Head>
);

export default AppHead;
