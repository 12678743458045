import Text from './Text';

const setTextPreset = (type, defaultProps) => {
  return (props) => <Text {...{ ...defaultProps, ...props }} type={type} />;
};

const HeadingDefaultProps = {
  color: 'secondary',
};

// Headings
export const H1 = setTextPreset('h1', HeadingDefaultProps);
export const H5 = setTextPreset('h5', HeadingDefaultProps);
export const H6 = setTextPreset('h6', HeadingDefaultProps);

// Body Texts
export const Overline = setTextPreset('overline', { isUpperCase: true });
