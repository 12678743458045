import PopoverMui from '@mui/material/Popover';

import { styled } from './lib';

const PopoverPosition = {
  Left: 'left',
  Right: 'right',
};

export const setPopoverBoxStyle = ({ theme }) => `
  background: ${theme.color.ACVGreyShades.uiWhiteColor};
  border: 1px solid ${theme.color.ACVBgLm.border1Color};
  border-radius: 4px;
  box-shadow: none;
  margin-top: 17px;
`;

const Popover = styled(
  ({ className, position = PopoverPosition.Right, ...props }) => (
    <PopoverMui
      {...props}
      classes={{ paper: className }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: position,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: position,
      }}
    />
  ),
  {
    shouldForwardProp: (name) => !['width'].includes(name),
  }
)`
  ${setPopoverBoxStyle}
  ${({ width }) => width && `width: ${width};`}
`;

export default Popover;
