import React, { createContext, useContext, useState } from 'react';

const NotificationContext = createContext({
  notifications: [],
  unReadCount: 0,
  addNotification: () => {},
  setUnreadNotificationCount: () => {},
});

export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationContextProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unReadCount, setUnreadCount] = useState([]);

  const addNotification = (notification) => {
    setNotifications(notification);
  };

  const setUnreadNotificationCount = (count) => {
    setUnreadCount(count);
  };

  const contextValue = {
    notifications,
    addNotification,
    unReadCount,
    setUnreadNotificationCount,
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};
