import useNotifications from '@portal/vehicle/hooks/useNotifications';
import {
  isWithinPollInterval,
  truncateWithEllipsis,
  TOAST_DURATION,
} from '@portal/lib';
import { PhotoUploadToast } from '@portal/notifications';
import { useEffect } from 'react';
const NotificationPoll = () => {
  const { notificationData } = useNotifications(true);

  useEffect(() => {
    if (notificationData?.notifications?.length > 0) {
      const showNotificationToast = (notifications) => {
        notifications?.forEach((notification) => {
          const { created_at, read, vehicle_id, vehicle } = notification || {};
          if (
            !read &&
            isWithinPollInterval(created_at) &&
            vehicle?.vehicle_images?.length > 0
          ) {
            const {
              customer_first_name,
              customer_last_name,
              year,
              make,
              model,
              trim,
              vehicle_images,
            } = vehicle;
            const name = `${customer_first_name} ${customer_last_name}`.trim();
            const thumbnailURL = vehicle_images?.[0]?.link;
            const vehicleName = truncateWithEllipsis(
              `${year || ''} ${make || ''} ${model || ''} ${trim || ''}`,
              35
            );
            PhotoUploadToast({
              duration: TOAST_DURATION,
              thumbnailURL,
              leadId: vehicle_id || '',
              userName: name || '',
              vehicleName,
            });
          }
        });
      };

      showNotificationToast(notificationData?.notifications);
    }
  }, [notificationData]);

  return null;
};
export default NotificationPoll;
