import CircularProgress from '@mui/material/CircularProgress';

import { styled } from './lib';

const LoaderCircleSize = {
  Md: 'md',
  Sm: 'sm',
};

const LoaderCircleSizeMap = {
  [LoaderCircleSize.Md]: '44px',
  [LoaderCircleSize.Sm]: '24px',
};

const LoaderCircle = ({ size = LoaderCircleSize.Md, ...props }) => (
  <CircularProgressStyled {...props} size={LoaderCircleSizeMap[size]} />
);

export default LoaderCircle;

const CircularProgressStyled = styled(CircularProgress)`
  color: ${({ theme }) => theme.color.Drivably.primaryColor};
`;
