import AvatarMui from '@mui/material/Avatar';

import { styled } from './lib';
import Text from './Text';

const Avatar = ({ text = '', ...props }) => (
  <AvatarMuiStyled {...props}>
    <Text color="brandContrast" type="subtitle3">
      {text}
    </Text>
  </AvatarMuiStyled>
);

export default Avatar;

const AvatarMuiStyled = styled(AvatarMui)`
  width: 30px;
  height: 30px;

  background: ${({ theme }) => theme.color.ACVPrimaryShadesLm.primaryColorBase};
`;
