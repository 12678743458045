import { unformatNumber, unformatPrice } from './number';

export const sortFloating = (a, b) => {
  const valueA = unformatNumber(a),
    valueB = unformatNumber(b);

  if (valueA === null && valueB === null) return 0;
  if (valueA === null) return -1;
  if (valueB === null) return 1;

  return valueA - valueB;
};

export const sortPrice = (a, b) => {
  const priceA = unformatPrice(a),
    priceB = unformatPrice(b);

  if (priceA === null && priceB === null) return 0;
  if (priceA === null) return -1;
  if (priceB === null) return 1;

  return priceA - priceB;
};
