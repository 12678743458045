import { useRef } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import { useIntersectionObserver } from '@portal/hooks';
import { styled } from './lib';
import Box from './Box';
import { Checkbox, Text } from '@drivably-apps/component-lib';

const FilterOption = ({
  onToggle,
  option,
  optionLabel,
  optionValue,
  isSelected,
}) => {
  const elementRef = useRef(null);
  const entry = useIntersectionObserver(elementRef, {});
  const isVisible = Boolean(entry?.isIntersecting);

  const handleItemClick = () => {
    onToggle({
      isSelected: !isSelected,
      label: optionLabel,
      option,
      value: optionValue,
    });
  };

  return (
    <div ref={elementRef}>
      {isVisible ? (
        <ListItem key={optionValue} disablePadding>
          <ListItemButtonStyled
            role={undefined}
            onClick={handleItemClick}
            dense
          >
            <ListItemIconStyled>
              <Checkbox isChecked={isSelected} />
            </ListItemIconStyled>
            <Text color="gray-text" align="left">
              {optionLabel}
            </Text>
          </ListItemButtonStyled>
        </ListItem>
      ) : (
        // Should have the same height as ListItem to retain smooth scrolling
        <Box height="54px" width="100%" />
      )}
    </div>
  );
};

export default FilterOption;

const ListItemIconStyled = styled(ListItemIcon)`
  min-width: unset;
  margin-right: -10px;
`;

const ListItemButtonStyled = styled(ListItemButton)`
  padding: 5px 11px;
  width: 100%;
`;
