import { createTheme } from '@mui/material/styles';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

/**
 * Design tokens derived from Figma. Tokens not found or derived from Figma go
 * into `custom` or `raw` object.
 */
export const DrivablyTheme = createTheme({
  color: {
    Drivably: {
      primaryColor: '#2159F2',
      secondaryColorDark: '#072578',
    },
    backgrounds: {
      white: '#FFFFFF',
      borders: '#DCDFE8',
      gray: '#757575',
      black: '#000000',
      green: '#3B873E',
      blue: '#094EE8',
    },
    shadow: {
      gray: '#84848433',
    },
    text: {
      text3: '#00000080',
      contrast: '#000000ab',
    },
    ACVGreyShades: {
      uiWhiteColor: '#FFFFFF',
    },
    ACVBgLm: {
      background1Color: '#F1F1F1',
      background2Color: '#FAFAFA',
      background3Color: '#E0E0E0',
      background4Color: '#E3F1FC',
      border1Color: '#DCDFE8',
      border2Color: '#FFC000',
    },
    ACVTextLm: {
      textDisabledColor: '#A1A1A1',
      textPrimaryColor: '#212121',
      textSecondaryColor: '#545454',
    },
    ACVInformationalShades: {
      uiInformationalColorXLight: '#EBF6FF',
      uiInformationalColorBase: '#004E7D',
    },
    ACVPrimaryShadesLm: {
      primaryColorXLight: '#FEF2EC',
      primaryColorBase: '#F26522',
    },
    ACVErrorAlertShades: {
      uiErrorColorXLight: '#FFEBEB',
      uiErrorColorBase: '#FC4243',
    },
    ACVSuccessShadesLm: {
      uiSuccessColorXLight: '#F0FAF6',
      uiSuccessColorBase: '#00A878',
    },
    True360TextLM: {
      textInverseColor: '#FFFFFF',
    },
    GlobalText: {
      tertiaryColor: '#757575',
    },
    // Unknown values in Figma
    raw: {
      white: '#FFFFFF',
      blackAlpha1: '#00000080',
      blackAlpha2: '#000000DE',
      gray1: '#DCDFE8',
      gray2: '#FAFAFA',
      gray3: '#E5E5E5',
      gray4: '#F3F3F4',
    },
  },
  typography: {
    GlobalHeading: {
      h5Bold: {
        fontFamily: 'Roboto',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '36px',
        letterSpacing: '0',
      },
      h6Medium: {
        fontFamily: 'Roboto',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '30px',
        letterSpacing: '0.15px',
      },
      subtitleSmRegular: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '21px',
        letterSpacing: '0.1px',
      },
      subtitleBaseBold: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '24px',
        letterSpacing: '0.15px',
      },
    },
    GlobalText: {
      button: {
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '14px',
        letterSpacing: '1.25px',
      },
      bodyBaseRegular: {
        fontFamily: 'Roboto',
        fontWeight: '400',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
      },
      statusBadgeBase: {
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '12px',
        letterSpacing: '0.4px',
      },
      overline: {
        fontFamily: 'Roboto',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '16px',
        letterSpacing: '1.5px',
      },
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '18px',
      letterSpacing: '0.1px',
    },
    subtitle3: {
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '30px',
      letterSpacing: '1.25px',
    },
    caption: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '18px',
      letterSpacing: '0.4px',
    },
    raw: {
      tableHeader: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '16px',
        letterSpacing: '0',
      },
      tableCell: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '16px',
        letterSpacing: '0px',
      },
      h1: {
        fontFamily: 'Roboto',
        fontSize: '39px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '36px',
        letterSpacing: '0',
      },
      menuText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
      },
    },
  },
  custom: {},
});
