import { useDetectDevice } from '@drivably-apps/component-lib';
import Avatar from './Avatar';
import Flex from './Flex';
import Text from './Text';

const LayoutUserInfo = ({
  email = '',
  name = '',
  storeId = '',
  storeName = '',
}) => {
  const { isMobile } = useDetectDevice();

  return (
    <Flex
      alignItems="center"
      spacing="11px"
      sx={{ paddingLeft: isMobile ? '8px' : 0 }}
    >
      <Avatar text={name.charAt(0)} id="current-user" data-email={email} />
      <Flex direction="column">
        <Text type="subtitle2">{name}</Text>
        {storeName && !isMobile && (
          <Text id="current-store" data-id={storeId} type="caption">
            {storeName}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default LayoutUserInfo;
