export const Thumbnail = {
  height: '64px',
  width: '64px',
};

export const NotificationUnReadColor = '#4D91BD';

export const boxProp = {
  isFlex: true,
  justify: 'center',
  align: 'center',
  width: 'full',
  height: '200',
  padding: 'sm',
};
