import { useState, useMemo } from 'react';
import {
  Input,
  Button,
  Link,
  Popover,
  List,
  IconSearch,
  IconClose,
  Box,
  Text,
} from '@drivably-apps/component-lib';

import { styled } from './lib';
import FilterOption from './FilterOption';

const Filter = ({
  anchorRef = { current: null },
  clearAllText = 'Clear All',
  getOptionLabel = (option) => option,
  getOptionValue = (option) => option,
  isOpen,
  isSingleSelect = false,
  onChange,
  onClose,
  options = [],
  saveText = 'Done',
  title = '',
  value = [],
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const handleToggle = (data) => {
    let newValue;

    if (data.isSelected) {
      newValue = isSingleSelect ? [data.option.value] : [...value, data.value];
    } else {
      newValue = value.filter((v) => v !== data.value);
    }

    onChange?.({ ...data, value: newValue });
  };

  const handleClearAll = () => {
    onChange?.([]);
    setSearchTerm('');
  };

  const renderedOptions = useMemo(
    () =>
      options.map((option, index) => {
        const optionLabel = getOptionLabel(option);

        if (
          searchTerm &&
          !`${optionLabel}`.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return null;
        }

        const optionValue = getOptionValue(option);
        const isSelected = value.includes(optionValue);

        return (
          <FilterOption
            key={`${searchTerm}-${index}`}
            optionLabel={optionLabel}
            optionValue={optionValue}
            onToggle={handleToggle}
            option={option}
            isSelected={isSelected}
          />
        );
      }),
    [options, searchTerm, value]
  );

  return (
    <Popover
      {...props}
      isOpen={isOpen}
      onClose={onClose}
      target={anchorRef.current}
      width="300"
    >
      <Box direction="column" padding="xl">
        <Box justify="space-between" align="center" marginBottom="md">
          <Text headingType="h6" size="lg">
            {title}
          </Text>
          <IconClose onClick={onClose} />
        </Box>
        <Input
          onChange={(e) => setSearchTerm(e.target.value)}
          pre={<IconSearch iconColor="gray" width="55" />}
          value={searchTerm}
          width="full"
        />
        <ListStyled items={renderedOptions} />
        <Box justify="space-between" align="center">
          <Link onClick={handleClearAll}>{clearAllText?.toUpperCase()}</Link>
          <Button onClick={onClose} size="unset">
            {saveText}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

export default Filter;

const ListStyled = styled(List)`
  max-height: 206px;
  overflow: auto;
  margin: 10px 0 20px 0 !important;
`;
