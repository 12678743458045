import { useMemo, useRef, useState } from 'react';

/**
 * Manage dynamic horizontal scrolling based on pagination.
 *
 * Given the code snippet -> <div class="scroll-source"><div
 * class="scroll-target"><!-- n children --></div></div>
 *
 * 1. Set scrollSourceRef as ref to the container element (e.g. scroll-source)
 * 2. Set scrollTargetRef as ref to the element to dynamically scroll (e.g.
 *    scroll-target)
 * 3. Set styles on the Source element: overflow hidden and consistent width (use
 *    flex-grow, fixed width and/or max width)
 * 4. Set styles on the Target element: transition property for transform style
 *    (e.g. transition: transform 0.3s ease)
 * 5. Set the returned value `translateX` as inline style for the Target Element
 *    (e.g. style={{ transform: `translateX(${translateX}px)` }}). This will
 *    perform the dynamic scrolling.
 */
export const useScroll = () => {
  const [page, setPage] = useState(0);

  const scrollSourceRef = useRef(null);
  const scrollTargetRef = useRef(null);

  const {
    childElementsSpec,
    firstChildElementSpec,
    hasElementsToScroll,
    pixelsPerPage,
    totalPages,
  } = useMemo(() => {
    // Save the left and width of all child elements
    const childElementsSpec = scrollTargetRef.current?.children
      ? Array.from(scrollTargetRef.current.children).map((child) =>
          child.getBoundingClientRect()
        )
      : [];

    const firstChildElementSpec = childElementsSpec[0] ?? {
      left: 0,
    };

    const lastChildElementSpec = childElementsSpec[
      childElementsSpec.length - 1
    ] ?? {
      left: 0,
      width: 0,
    };

    // Calculate essential numbers to implement pagination
    const totalPixels = lastChildElementSpec.left + lastChildElementSpec.width;
    const pixelsPerPage = scrollSourceRef.current?.clientWidth;
    const totalPages = Math.floor(totalPixels / pixelsPerPage);
    const hasElementsToScroll = totalPixels > pixelsPerPage;

    return {
      childElementsSpec,
      firstChildElementSpec,
      hasElementsToScroll,
      pixelsPerPage,
      totalPages,
    };
  }, [scrollSourceRef.current, scrollTargetRef.current]);

  const handlePrevPage = () =>
    setPage((state) => (state - 1 + totalPages) % totalPages);

  const handleNextPage = () => setPage((state) => (state + 1) % totalPages);

  const handleResetPage = () => {
    if (page !== 0) {
      setPage(0);
    }
  };

  // Compute dynamic position
  const translateX = useMemo(() => {
    const startPixelByPage = page * pixelsPerPage;
    let translateX = 0;

    if (page >= 1) {
      for (const childElementSpec of childElementsSpec) {
        const { left, width } = childElementSpec;

        // Ensure next page's pixel is within an element's spec to prevent split view.
        // Improve accuracy by considering the left position of first child.
        if (startPixelByPage >= left && startPixelByPage < left + width) {
          translateX = (left - firstChildElementSpec.left) * -1;
          break;
        }
      }
    }

    return translateX;
  }, [page, pixelsPerPage, childElementsSpec, firstChildElementSpec]);

  return {
    handleNextPage,
    handlePrevPage,
    handleResetPage,
    hasElementsToScroll,
    scrollSourceRef,
    scrollTargetRef,
    translateX,
  };
};
