import Box from './Box';
import Flex from './Flex';
import LayoutNavBar from './LayoutNavBar';
import LayoutSideBar from './LayoutSideBar';

const LayoutStyle = {
  DrawerWidth: '260px',
  NavbarHeight: '58px',
};

const Layout = ({
  activePath,
  children,
  cobrand,
  email,
  hasNavBar = true,
  hasSideBar = false,
  menuItems = [],
  navigationItems,
  onLogoClick,
  onLogoutClick,
  onNavigationItemClick,
  storeId,
  storeName,
  userName,
}) => (
  <Flex
    {...(hasNavBar && { paddingTop: LayoutStyle.NavbarHeight })}
    height="100vh"
    direction="column"
  >
    {hasNavBar && (
      <LayoutNavBar
        cobrand={cobrand}
        email={email}
        height={LayoutStyle.NavbarHeight}
        items={menuItems}
        onLogoClick={onLogoClick}
        onLogoutClick={onLogoutClick}
        storeId={storeId}
        storeName={storeName}
        userName={userName}
      />
    )}
    <Flex flexGrow={1} minHeight="0">
      {hasSideBar && (
        <LayoutSideBar
          activePath={activePath}
          items={navigationItems}
          onItemClick={onNavigationItemClick}
          width={LayoutStyle.DrawerWidth}
        />
      )}
      <Box flexGrow={1}>{children}</Box>
    </Flex>
  </Flex>
);

export default Layout;
