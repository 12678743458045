import {
  Box,
  ButtonBase,
  IconClose,
  Image,
  Label,
  Link,
  Text,
} from '@drivably-apps/component-lib';
import { toast } from 'react-hot-toast';

import { useLocale } from '@portal/hooks';
import { styled } from '@portal/ui/lib';
import { Route } from '@portal/lib';

const PhotoUploadToast = ({
  duration = 5000,
  thumbnailURL,
  leadId,
  userName,
  vehicleName,
}) => {
  const { locale } = useLocale();
  const leadDetailPath = Route?.leadList.path;

  const onToastClose = (event, t) => {
    event.preventDefault();
    toast.dismiss(t?.id);
  };

  const renderNotificationText = userName
    ? `${userName} ${locale.notificationPhotoUploadedFor}`
    : locale.notificationPhotosHaveBeenUploadedFor;

  toast.custom(
    (t) => (
      <Container
        style={{
          opacity: t.visible ? 1 : 0,
        }}
      >
        <Link href={`${leadDetailPath}/${leadId}`} target="blank">
          <Box justify="space-between">
            <Label margin="lg" weight="semibold" color="gray-text">
              {locale.notificationPhotoUploadTitle}
            </Label>
            <ButtonBase
              onClick={(event) => onToastClose(event, t)}
              marginRight="lg"
            >
              <IconClose />
            </ButtonBase>
          </Box>
          <Box flexGap="lg" paddingHorizontal="lg" paddingBottom="lg">
            <Image
              src={thumbnailURL}
              alt="thumbnail"
              width="64px"
              height="64px"
              radius="md"
            />
            <Box direction="column" justify="center">
              <Text size="xs" color="gray-text" marginBottom="xs">
                {renderNotificationText}
              </Text>
              <Text weight="semibold" color="gray-text">
                {vehicleName}
              </Text>
            </Box>
          </Box>
        </Link>
      </Container>
    ),
    {
      duration,
    }
  );
};

export default PhotoUploadToast;

const Container = styled(Box)`
  background-color: ${({ theme }) => theme.color.backgrounds.white};
  box-shadow: 0px 1px 10px 5px ${({ theme }) => theme.color.shadow.gray};
  width: 405px;
`;
