import { useCallback, useState } from 'react';

import { Tab as MuiTab, Tabs as TabsMui } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';

import { styled } from './lib';
import TabPanel from './TabPanel';

const Tabs = ({
  tabs = [],
  defaultIndex = 0,
  tabContainer = {},
  tabHeaderStyles = {},
  tabPanelStyles = {},
}) => {
  const theme = useTheme();
  const [index, setValue] = useState(defaultIndex);

  const handleChange = useCallback((_, newValue) => setValue(newValue), []);
  const handleChangeIndex = useCallback((index) => setValue(index), []);

  const a11yProps = useCallback(
    (index) => ({
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    }),
    []
  );

  return (
    <>
      <TabsMui
        value={index}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        variant="fullWidth"
        aria-label="Vehicle offer and condition report tabs"
        style={tabContainer}
      >
        {tabs.map((tab, index) => (
          <TabMuiStyled
            key={`tab-${tab.name}`}
            style={tabHeaderStyles}
            label={tab.name}
            {...a11yProps(index)}
          />
        ))}
      </TabsMui>

      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={index}
        onChangeIndex={handleChangeIndex}
      >
        {tabs.map((tab, tabIndex) => (
          <TabPanel
            style={tabPanelStyles}
            key={`tab-panel-${tab.name}`}
            value={index}
            index={tabIndex}
            dir={theme.direction}
          >
            {tab.content}
          </TabPanel>
        ))}
      </SwipeableViews>
    </>
  );
};

const TabMuiStyled = styled(MuiTab)`
  text-transform: none;
`;

export default Tabs;
