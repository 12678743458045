export const compareEqualObjects = (origin, target) => {
  if (origin === target) {
    return true;
  } else if (origin instanceof Date && target instanceof Date) {
    return origin.getTime() === target.getTime();
  } else if (
    !origin ||
    !target ||
    (typeof origin !== 'object' && typeof target !== 'object')
  ) {
    return origin === target;
  } else if (origin.prototype !== target.prototype) {
    return false;
  } else {
    const keys = Object.keys(origin);
    if (keys.length !== Object.keys(target).length) {
      return false;
    }
    return keys.every((key) => compareEqualObjects(origin[key], target[key]));
  }
};

export const objectIsEmpty = (object) => !!Object.keys(object).length;

export const areEqualObjects = (initObj = {}, compareObj = {}) => {
  return (
    typeof initObj === 'object' &&
    typeof compareObj === 'object' &&
    JSON.stringify(initObj) === JSON.stringify(compareObj)
  );
};

export const reverseObjectProperties = (sourceObj) =>
  Object.entries(sourceObj)
    .map(([key, value]) => [value, key])
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
