import { useQuery } from '@portal/hooks';
import { useNotificationContext } from '@portal/app';
import { NotificationsPollingGql } from '@portal/query/graphql';
import { NOTIFICATION_POLL_INTERVAL } from '@portal/lib';

//fetch notification hook
const useNotifications = (isPoll = false) => {
  const { addNotification, setUnreadNotificationCount } =
    useNotificationContext();

  const { data: notificationData, refetch: fetchNotifications } = useQuery(
    NotificationsPollingGql,
    {
      ...(isPoll && {
        pollInterval: NOTIFICATION_POLL_INTERVAL,
      }),
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const { notifications, notifications_aggregate } = data || {};

        if (!notifications || !Array.isArray(notifications)) return;
        // Update notification context
        addNotification(notifications);
        // update unread count context
        setUnreadNotificationCount(
          notifications_aggregate?.aggregate.count || 0
        );
      },
    }
  );
  return { notificationData, fetchNotifications };
};

export default useNotifications;
