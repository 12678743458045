import Svg from '@portal/ui/Svg';

const Dealer = ({ width = 18, height = 19, ...props }) => (
  <Svg
    {...props}
    width={width}
    height={height}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 13C15.75 13 14.55 12.8 13.43 12.43C13.08 12.32 12.69 12.4 12.41 12.67L10.21 14.87C7.38 13.43 5.06 11.12 3.62 8.29L5.82 6.08C6.1 5.81 6.18 5.42 6.07 5.07C5.7 3.95 5.5 2.75 5.5 1.5C5.5 0.95 5.05 0.5 4.5 0.5H1C0.45 0.5 0 0.95 0 1.5C0 10.89 7.61 18.5 17 18.5C17.55 18.5 18 18.05 18 17.5V14C18 13.45 17.55 13 17 13ZM9 0.5V10.5L12 7.5H18V0.5H9Z"
      fill="#2159F2"
    />
  </Svg>
);

export default Dealer;
