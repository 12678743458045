import { useRef } from 'react';

/** Trigger only the last function call from successive calls */
export const useDebounceCallback = (onChange, delay = 300) => {
  const timeoutId = useRef();

  const handleChangeDebounce = (...args) => {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => onChange?.(...args), delay);
  };

  return handleChangeDebounce;
};
