import LoaderCircle from './LoaderCircle';

import { styled } from './lib';

const TableLoader = () => (
  <TableLoaderStyled className="ag-overlay-loading-center">
    <LoaderCircle />
  </TableLoaderStyled>
);

export default TableLoader;

const TableLoaderStyled = styled('div')`
  && {
    border: 0;
    background: none;
    box-shadow: none;
    padding: 0;
  }
`;
