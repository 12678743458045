import MenuMui from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { styled } from './lib';
import Text from './Text';
import { setPopoverBoxStyle } from './Popover';

const MenuPosition = {
  Left: 'left',
  Right: 'right',
};

const Menu = ({
  anchorRef = { current: null },
  isOpen = false,
  onClose,
  options = [],
  position = MenuPosition.Right,
  ...props
}) => {
  const renderedMenuItems = options.map(({ text, onClick }, key) => (
    <MenuItem key={key} onClick={onClick}>
      <Text>{text}</Text>
    </MenuItem>
  ));

  return (
    <MenuMuiStyled
      {...props}
      anchorEl={anchorRef.current}
      onClose={onClose}
      open={isOpen}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: position,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: position,
      }}
    >
      {renderedMenuItems}
    </MenuMuiStyled>
  );
};

export default Menu;

const MenuMuiStyled = styled(({ className, ...props }) => (
  <MenuMui {...props} classes={{ paper: className }} />
))`
  ${setPopoverBoxStyle}
  &.Mui-focusVisible {
    background: ${({ theme }) => theme.color.ACVGreyShades.uiWhiteColor};
  }
`;
