import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { styled } from './lib';
import A from './A';
import Box from './Box';
import Text, { setTextColor, TextRootCls } from './Text';

const LayoutSideBar = ({ activePath = '', items = [], onItemClick, width }) => (
  <DrawerStyled variant="permanent" width={width}>
    <Box isOverflowAuto paddingX="24px" paddingY="31px">
      <ListStyled>
        {items
          .filter((item) => item.display)
          .map(({ text, path }) => {
            const isActive = activePath === path;

            return (
              <ListItemStyled
                key={text}
                onClick={() => !isActive && onItemClick({ text, path })}
                isActive={isActive}
              >
                <A href={path} isPlaceholder>
                  <Text color={isActive ? 'brand' : 'base'}>{text}</Text>
                </A>
              </ListItemStyled>
            );
          })}
      </ListStyled>
    </Box>
  </DrawerStyled>
);

export default LayoutSideBar;

const DrawerStyled = styled(Drawer)`
  width: ${({ width }) => width};
  flex-shrink: 0;

  & .MuiDrawer-paper {
    width: ${({ width }) => width};
    box-sizing: border-box;
    border: 0;
    background: ${({ theme }) => theme.color.backgrounds.white};
    position: static;
  }
`;

const ListStyled = styled(List)`
  padding: 0;
`;

const ListItemStyled = styled(ListItem, {
  shouldForwardProp: (name) => !['isActive'].includes(name),
})`
  padding: 4px 0;
  margin-bottom: 24px;
  transition:
    border-left 0.2s,
    padding-left 0.2s,
    color 0.2s;

  &:hover {
    border-left: 2px solid ${({ theme }) => theme.color.Drivably.primaryColor};
    padding-left: 18px;

    ${TextRootCls} {
      ${({ theme }) => setTextColor({ color: 'brand', theme })}
    }
  }

  ${({ isActive, theme }) =>
    isActive
      ? `
          border-left: 2px solid ${theme.color.Drivably.primaryColor};
          padding-left: 18px;
          cursor: default;
        `
      : `cursor: pointer;`}
`;
