import { styled } from './lib';
import Input from './Input';

const Textarea = ({ rows = 2, textAlign = 'left', ...props }) => (
  <InputStyled {...props} multiline rows={rows} textAlign={textAlign} />
);

export default Textarea;

const InputStyled = styled(Input)`
  .MuiFilledInput-root {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;
