import React, { useMemo } from 'react';
import { NetworkStatus } from '@apollo/client';
import { Box, ButtonBase, Text } from '@drivably-apps/component-lib';
import TablePagination from '@portal/ui/TablePagination';
import styled from '@emotion/styled';
import { useLocale, useMutation, useQuery } from '@portal/hooks';
import {
  FetchUnReadNotificationsGql,
  NotificationUpdateMarkAllReadGql,
} from '@portal/query/graphql';
import NotificationRow from './NotificationRow';
import { getNewEarlyList } from './lib';
import { useNotificationContext } from '@portal/app';

const MarkAllAsReadComponent = ({
  handleMarkAllRead,
  isMarkAllReadDisabled,
}) => {
  const { locale } = useLocale();
  return (
    <Box justify="flex-end">
      <ButtonBase
        onClick={handleMarkAllRead}
        isDisabled={isMarkAllReadDisabled}
      >
        <Text color="primary-success" size="md" weight="semibold">
          {locale.notificationMarkAllRead}
        </Text>
      </ButtonBase>
    </Box>
  );
};

const Notification = (props) => {
  const { locale } = useLocale();
  const { setUnreadNotificationCount } = useNotificationContext();

  const {
    data,
    isLoading,
    limit,
    onLimitChange,
    onNextPageClick,
    onPrevPageClick,
    page,
    total,
    showPagination = true,
    showPanelNotification = false,
  } = props;

  const {
    data: unReadList,
    refetch,
    networkStatus,
  } = useQuery(FetchUnReadNotificationsGql, {
    notifyOnNetworkStatusChange: true,
  });

  const [updateNotificationMarkAllRead, { isLoading: isMarkAllReadLoading }] =
    useMutation(NotificationUpdateMarkAllReadGql, {
      isSuccessAlertMuted: true,
      refetchQueries: [
        {
          query: FetchUnReadNotificationsGql,
        },
      ],
    });

  const notificationList = useMemo(() => {
    return getNewEarlyList(data);
  }, [data]);

  const handleMarkAllRead = async () => {
    await refetch();
    const unreadIds = unReadList?.notifications?.map((item) => item.id);
    const variables = {
      unreadIds,
    };
    updateNotificationMarkAllRead({ variables });
  };

  const { newList, earlierList } = notificationList;

  if (!isLoading && !data?.length) {
    return (
      <Box align="center" height="full" justify="center" width="full">
        <Text weight="bold" size="lg">
          {locale.noNotifications}
        </Text>
      </Box>
    );
  }

  const isMarkAllReadDisabled =
    networkStatus === NetworkStatus.refetch || isMarkAllReadLoading;

  // after refetching unRedNotification query update count context value
  if (NetworkStatus.ready) {
    setUnreadNotificationCount(unReadList?.notifications?.length);
  }

  return (
    <NotificationContainer
      isFlex
      direction="column"
      showPanelNotification={showPanelNotification}
      width={showPanelNotification && 'full'}
    >
      {!!unReadList?.notifications?.length && !showPanelNotification && (
        <MarkAllAsReadComponent
          handleMarkAllRead={handleMarkAllRead}
          isMarkAllReadDisabled={isMarkAllReadDisabled}
        />
      )}

      {/* new notification list  */}
      {!!newList.length && (
        <Box isFlex direction="column" marginTop="xl">
          <Box isFlex direction="row" justify="space-between">
            <Text weight="bold" marginBottom="md" size="lg">
              {locale.formatString(locale.notificationNew, newList.length)}
            </Text>
            {!!unReadList?.notifications?.length && showPanelNotification && (
              <MarkAllAsReadComponent
                handleMarkAllRead={handleMarkAllRead}
                isMarkAllReadDisabled={isMarkAllReadDisabled}
              />
            )}
          </Box>
          {newList.map((notification) => (
            <NotificationRow
              notification={notification}
              key={notification?.vehicle_id}
              showPanelNotification={showPanelNotification}
            />
          ))}
        </Box>
      )}

      {/* earlier notification list  */}
      {!!earlierList.length && (
        <Box isFlex direction="column" marginTop="xl">
          <Box isFlex direction="row" justify="space-between">
            <Text weight="bold" marginBottom="md" size="lg">
              {locale.formatString(locale.notificationEarlier)}
            </Text>
            {!!unReadList?.notifications?.length &&
              showPanelNotification &&
              !newList?.length && (
                <MarkAllAsReadComponent
                  handleMarkAllRead={handleMarkAllRead}
                  isMarkAllReadDisabled={isMarkAllReadDisabled}
                />
              )}
          </Box>
          {earlierList.map((notification) => (
            <NotificationRow
              notification={notification}
              key={notification?.vehicle_id}
              showPanelNotification={showPanelNotification}
            />
          ))}
        </Box>
      )}

      {showPagination && (
        <Box justify="flex-end" marginTop="xl">
          <TablePagination
            isLoading={isLoading}
            limit={limit}
            onLimitChange={onLimitChange}
            onNextPageClick={onNextPageClick}
            onPrevPageClick={onPrevPageClick}
            page={page}
            pageLabel={locale.notificationPaginationText}
            pageStatsText={locale.notificationPageStatsText}
            total={total}
          />
        </Box>
      )}
    </NotificationContainer>
  );
};

export default Notification;

const NotificationContainer = styled(Box)`
  margin: ${(props) => !props?.showPanelNotification && '90px 100px 0'};
`;
