import { Box } from '@drivably-apps/component-lib';

/** ModalBoxBase provides the base styled container within an overlay. */
export const ModalBoxBase = ({ children, gap = 20 }) => (
  // TODO support flex-gap and overflow on component-lib box
  <Box
    className="clctrd-modal-box-base"
    color="light"
    direction="column"
    height="full"
    padding="lg"
    style={{
      gap: gap,
      overflow: 'auto',
    }}
    width="full"
  >
    {children}
  </Box>
);
