import { formatDate } from './date';

export const exportFilterDate = ({ value: dateValue = {} }) =>
  formatDate(dateValue, 'YYYY-MM-DD');

export const exportFilterTime = ({ value: timeValue = {} }) =>
  formatDate(timeValue, 'hh:mm A');

export const exportFilterStatus = ({ value: status = {}, locale }) => {
  const filterStatus = Object.keys(status).filter(
    (st) => typeof status[st] === 'boolean' && status[st] && status
  )[0];

  return (
    (filterStatus && filterStatus.replace('_', ' ').toUpperCase()) ||
    locale.appEmptyField
  );
};

export const exportFilterAppointmentStatus = ({
  value: status = false,
  locale,
}) =>
  status ? locale.appointmentStatusUpcoming : locale.appointmentStatusDone;

export const exportFilterValidValue = ({ value, locale }) =>
  value ? value : locale.appEmptyField;

export const exportFilterInspection = ({
  value: inspection_request,
  locale,
}) =>
  inspection_request
    ? locale.vehicleInspectionRequested
    : locale.vehicleInspectionNotRequested;
