import { useState, useEffect } from 'react';

import { createClient } from '@portal/lib';

/** Initialize client on browser only */
export const useClient = ({ token, isPublicRoute }) => {
  const [client, setClient] = useState(null);

  useEffect(() => {
    const instance = createClient(isPublicRoute ? '' : token);
    setClient(instance);
  }, [token]);

  return client;
};
